<template>
	<b-modal ref="deleteAccountModal" normal>
		<h3 class="margin-0" slot="header">Remover</h3>
		<div>
			<p class="ink-base font-size-big margin-0">
				Tem certeza que deseja remover a conta {{ accountObject.fullName }}?
			</p>
		</div>
		<div slot="footer" class="display-flex justify-content-end">
			<b-button
				class="margin-left-normal font-size-big"
				link
				color="gray"
				@clickOrEnter="$refs.deleteAccountModal.hideModal()"
				>Cancelar</b-button
			>
			<b-button
				:loading="loading"
				@clickOrEnter="submitForm()"
				class="margin-left-normal font-size-big"
				danger
				>Remover</b-button
			>
		</div>
	</b-modal>
</template>

<script>
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import { getRoleByName, getRolesByValues } from '../utils/roles.js';
import { mapActions } from 'vuex';

export default {
	name: 'app-delete-account-modal',
	components: {
		BModal,
		BButton
	},
	data() {
		return {
			internalAccount: false,
			loading: false,
			accountObject: Object
		};
	},
	methods: {
		...mapActions('accounts', ['deleteInternalAccount', 'deleteManagerAccount']),
		showModal(accountObject) {
			if (getRolesByValues(getRoleByName(accountObject.roles))[0].type.includes('A'))
				this.internalAccount = true;
			this.accountObject = accountObject;
			this.$refs.deleteAccountModal.showModal();
		},
		hideModal() {
			this.$refs.deleteAccountModal.hideModal();
		},
		toggleModal() {
			this.$refs.deleteAccountModal.toggleModal();
		},
		async submitForm() {
			this.loading = true;
			try {
				if (this.internalAccount) {
					await this.deleteInternalAccount({ _id: this.accountObject._id });
				} else {
					await this.deleteManagerAccount({ _id: this.accountObject._id });
				}
				this.$toasts.show({
					message: 'Conta removida com sucesso.',
					timeout: 3000,
					type: 'success'
				});
				this.hideModal();
				this.$emit('onRemoveAccount', this.accountObject);
			} catch (err) {
				this.$toasts.show({
					message: `${err.message}.`,
					timeout: 3000,
					type: 'danger'
				});
				this.hideModal();
			}
			this.loading = false;
		}
	}
};
</script>
