<template>
	<div class="flex filters-container" :class="{ invisible: !showFilters }">
		<div class="bg-white-base display-flex flex-column justify-content-between">
			<div
				class="
					padding-horizontal-big padding-top-big padding-bottom-xxbig
					margin-bottom-big margin-bottom-lg-0
				"
			>
				<div class="display-flex w100 justify-content-between">
					<h2 class="ink-base semi-bold margin-top-0 margin-bottom-big">Filtrar</h2>
					<bbox-button @clickOrEnter="closeFilter()" link class="margin-bottom-big"
						><bbox-icon><icon-clear class="ink-lighter-2 font-size-huge" /></bbox-icon
					></bbox-button>
				</div>

				<slot><!-- O conteúdo do filtro será inserido aqui --></slot>
			</div>

			<!-- footer fixo do filtro -->
			<div
				class="
					bottom-buttons
					row
					bg-white-base
					justify-content-end
					padding-big
					border-top border-white-darker-4
				"
			>
				<bbox-button
					class="font-size-big margin-0 margin-right-lg-big"
					@clickOrEnter="filterHandle()"
				>
					<span v-if="loadingFilterButton"
						><bbox-icon spin><icon-refresh class="font-size-big" /></bbox-icon
					></span>
					<span v-else>{{ filterButtonText }}</span>
				</bbox-button>
				<bbox-button
					class="font-size-big semi-bold padding-0 margin-top-normal margin-top-lg-0"
					link
					color="gray"
					@clickOrEnter="cleanFilters()"
					>Limpar filtros</bbox-button
				>
			</div>
		</div>
		<div class="bbox-filters-bg bg-white-darker-4" @click="closeFilter()"></div>
	</div>
</template>

<script>
import BboxButton from '@bossabox-code/design-system/components/Button.vue';
import BboxIcon from '@bossabox-code/design-system/components/Icon.vue';

export default {
	name: 'app-filter',
	props: {
		filterButtonText: {
			type: String,
			default: 'Filtrar',
		},
		loadingFilterButton: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		BboxButton,
		BboxIcon,
	},
	data() {
		return {
			showFilters: false,
			defaultStyle: '',
		};
	},
	methods: {
		openFilter() {
			this.showFilters = true;
			this.defaultStyle = document.querySelector('body, html').style.overflow;
			var bodyWidth = document.body.offsetWidth;
			document.querySelector('body, html').style.overflow = 'hidden';
			document.body.style.width = bodyWidth + 'px';
		},
		closeFilter() {
			this.showFilters = false;
			setTimeout(() => {
				document.querySelector('body, html').style.overflow = this.defaultStyle;
				document.body.style.width = 'unset';
			}, 200);
		},
		cleanFilters() {
			this.$emit('cleanFilters');
		},
		filterHandle() {
			this.$emit('filter');
		},
	},
};
</script>

<style lang="scss" scoped>
.filters-container {
	position: fixed;
	z-index: 15;
	top: 0;
	right: 0;
	width: 34%;

	.bottom-buttons {
		position: fixed;
		bottom: 0;
		z-index: 5;
		width: 34%;
		button {
			width: 100%;

			@media (min-width: 992px) {
				width: unset;
			}
		}
	}

	> div:first-child {
		position: fixed;
		z-index: 4;
		width: 34%;
		height: 100%;
		box-shadow: 0px 20px 25px #0000001a;
		overflow-y: auto;
	}

	@media (max-width: 991px) {
		width: 50%;

		.bottom-buttons {
			width: 50%;
		}

		> div:first-child {
			width: 50%;
		}
	}

	@media (max-width: 576px) {
		width: 100%;

		.bottom-buttons {
			width: 100%;
		}

		> div:first-child {
			width: 100%;
		}
	}
}
.filters-container.invisible {
	display: none;
}
.filter-mobile-button {
	transform: none;
	position: fixed;
	bottom: 1.6rem;
	left: 1.6rem;
}
div.bbox-filters-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	opacity: 0.65;
	transform: translate(0, 0) !important;
}
</style>
