import { challengesService } from '../services/challenges.service';

export default {
	namespaced: true,
	state: {
		evaluatedChallenges: [],
		notEvaluatedChallenges: [],
		challenge: {},
		challengesTotals: {}
	},
	mutations: {
		SET_EVALUATED_CHALLENGES(state, { challenges }) {
			state.evaluatedChallenges = challenges;
		},
		SET_NOT_EVALUATED_CHALLENGES(state, { challenges }) {
			state.notEvaluatedChallenges = challenges;
		},
		SET_CHALLENGE(state, { challenge }) {
			state.challenge = challenge;
		},
		SET_CHALLENGES_TOTALS(state, { challengesTotals }) {
			state.challengesTotals = challengesTotals;
		}
	},
	actions: {
		async getEvaluatedChallenges({ commit }, { role, page, limit }) {
			const challenges = await challengesService.getChallenges(role, 'evaluated', page, limit);
			commit('SET_EVALUATED_CHALLENGES', { challenges });
			return challenges;
		},
		async getNotEvaluatedChallenges({ commit }, { role, page, limit }) {
			const challenges = await challengesService.getChallenges(role, 'notEvaluated', page, limit);
			commit('SET_NOT_EVALUATED_CHALLENGES', { challenges });
			return challenges;
		},
		async getChallengesTotals({ commit }, { role }) {
			const challengesTotals = await challengesService.getChallengesTotals(role);
			commit('SET_CHALLENGES_TOTALS', { challengesTotals });
			return challengesTotals;
		},
		async updateChallengeInstance({ commit }, challengeInstance) {
			const challenge = await challengesService.updateChallengeInstance(challengeInstance);

			commit('SET_CHALLENGE', { challenge });
			return challenge;
		}
	},
	getters: {
		evaluatedChallenges(state) {
			return state.evaluatedChallenges;
		},
		notEvaluatedChallenges(state) {
			return state.notEvaluatedChallenges;
		},
		challenge(state) {
			return state.challenge;
		},
		challengesTotals(state) {
			return state.challengesTotals;
		}
	}
};
