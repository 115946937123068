<template>
	<div>
		<b-table
			class="horizontal-scroll"
			v-if="releasedPayments && releasedPayments.length"
			ref="invoicesTable"
			:items="releasedPayments"
			:fields="fields"
			:hasPagination="false"
			:selectable="activeTab === 'Para liberar'"
			cellSize="big"
			tableClasses="margin-horizontal-auto table-width margin-horizontal-xl-tiny margin-bottom-xbig display-flex flex-column"
		>
			<template slot="fullName" slot-scope="props">
				<div class="display-flex font-size-big flex-column">
					<span>{{ props.item.user.fullName }}</span>
					<span class="ink-lighter-1">{{ getRolesNames(props.item.user.roles) }}</span>
				</div>
			</template>

			<template slot="cnpj" slot-scope="props">
				<span class="font-size-big"> {{ props.item.user.companyInfo.cnpj }} </span>
			</template>

			<template slot="number" slot-scope="props">
				<span class="font-size-big">{{
					props.item.invoice ? props.item.invoice.number : ''
				}}</span>
			</template>

			<template slot="value" slot-scope="props">
				<span class="font-size-big">
					{{ props.item.invoice ? formatPayment(props.item.invoice.value) : '' }}
				</span>
			</template>

			<template slot="name" slot-scope="props">
				<span class="font-size-big">{{ props.item.product.name }}</span>
			</template>

			<template slot="updatedAt" slot-scope="props">
				<span class="font-size-big">{{
					props.item.approvedBy ? formatDate(props.item.approvedBy.updatedAt) : ''
				}}</span>
			</template>

			<template slot="status" slot-scope="props">
				<b-status
					:statusAmount="2"
					:activeIndex="getDetailsByStatus(props.item.status).index"
					:title="getDetailsByStatus(props.item.status).status"
					:text="getDetailsByStatus(props.item.status).message"
					:color="getDetailsByStatus(props.item.status).color"
					:titleColor="getDetailsByStatus(props.item.status).titleColor"
					:titleBackground="getDetailsByStatus(props.item.status).titleBackground"
				/>
			</template>
		</b-table>

		<div v-else>
			<p
				class="
					regular
					ink-lighter-1
					margin-top-big margin-left-auto margin-right-auto
					font-size-xbig
				"
			>
				Nenhum pagamento encontrado com o filtro inserido
			</p>
		</div>

		<b-filter-payments
			ref="releasedPaymentsFilter"
			:products="products"
			:roles="roles"
			:prolancers="prolancers"
			@onFilteredInvoices="onFilteredInvoices"
		/>
	</div>
</template>

<script>
import BTable from '@bossabox-code/design-system/components/Table.vue';

import BStatus from '@bossabox-code/shared-components/components/status/Status.vue';
import BFilterPayments from './FilterPayments.vue';

import financesService from '@/services/finances.service.js';

import financialStatus from '@/utils/financialStatus.js';
import { getRolesNames } from '@/utils/roles.js';

import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';

Vue.use(financesService);

export default {
	components: {
		BTable,
		BStatus,
		BFilterPayments,
	},
	data() {
		return {
			tabNames: ['Para liberar', 'Pagamentos liberados'],
			activeTab: 'Para liberar',
			fields: [
				{
					key: 'fullName',
					value: 'Prolancer'
				},
				{
					key: 'cnpj',
					value: 'CNPJ'
				},
				{
					key: 'number',
					value: 'Número da NF'
				},
				{
					key: 'value',
					value: 'Valor da NF'
				},
				{
					key: 'name',
					value: 'Projeto'
				},
				{
					key: 'updatedAt',
					value: 'Data de Liberação'
				},
				{
					key: 'status',
					value: 'Status'
				}
			],
			releasedPayments: [],
			products: [],
			roles: [],
			prolancers: [],
			retentionError: []
		};
	},
	props: {
		invoices: Array
	},
	computed: {
		...mapGetters('auth', ['user']),
		...mapState('payments', ['payments'])
	},
	async mounted() {
		this.releasedPayments = await this.$financesService.getPayments('released,completed,failed');

		//Mapeia os valores e transforma um array duplicado em um array único
		this.products = [...new Set(this.releasedPayments.map(payment => payment.product.name))];

		//Mapeia os valores e usa o flat para transformar array de array em um único array
		this.roles = [...new Set(this.releasedPayments.map(payment => payment.user.roles).flat())];

		//cria um map com chaves únicas e mapeia para retornar o objeto chave => valor
		this.prolancers = [
			...new Map(this.releasedPayments.map(payment => [payment.user._id, payment.user])).values()
		].map(item => {
			return { name: item.fullName, value: item._id };
		});
	},
	methods: {
		getRolesNames,
		openFilter() {
			this.$refs.releasedPaymentsFilter.openFilter();
		},
		formatDate(createdAt) {
			let date = new Date(createdAt);
			const day = date.toLocaleString('default', { day: 'numeric' });
			const month = date.toLocaleString('default', { month: 'short' }).replace('.', '');
			const year = date.toLocaleString('default', { year: 'numeric' });

			return `${day} ${this.capitalize(month)}, ${year}`;
		},
		capitalize(string) {
			if (typeof string !== 'string') return '';
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		formatPayment(payment) {
			return payment ? payment.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '';
		},
		getDetailsByStatus(status) {
			return financialStatus[status];
		},
		onFilteredInvoices(filteredInvoices) {
			this.releasedPayments = filteredInvoices;
		},
		getQueryString() {
			return this.$refs.releasedPaymentsFilter.getQueryString();
		},
	},
};
</script>

<style scoped lang="scss">
@media (max-width: 1200px) {
	.horizontal-scroll {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		height: 100%;
	}
}

.overflow-hidden {
	overflow-x: hidden;
}

.width-empty-div {
	width: 11%;
}

.page-menu {
	align-items: center;
}

@media (max-width: 990px) {
	.page-menu {
		flex-direction: column;
	}
	.page-menu > div:first-child {
		flex-direction: column;
	}
	.page-menu .switcher {
		width: fill-available;
	}

	.approve {
		width: fill-available;
	}

	.approve-div {
		width: 100%;
	}
}

.hidden {
	visibility: hidden;
}
</style>
