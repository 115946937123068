<template>
	<div class="container-box">
		<div class="forgot-password-box margin-horizontal-auto display-flex flex-column">
			<bbox-icon class="margin-horizontal-auto margin-top-xbig">
				<icon-bossabox class="logo"></icon-bossabox>
			</bbox-icon>

			<div class="display-flex flex-column" v-if="screen === 'forgot-password'">
				<h2
					class="font-size-huge semi-bold ink-base margin-top-lg-xxbig margin-top-big margin-bottom-big align-center"
				>
					Esqueceu a senha?
				</h2>
				<bbox-context-banner
					cardClasses="padding-vertical-normal padding-horizontal-big bg-red-lighter-3 red-base align-center font-size-big"
					ref="error"
				>
					<bbox-icon class="display-inline-block font-size-big" slot="icon">
						<icon-error />
					</bbox-icon>
					<p class="display-inline-block margin-0 font-size-big" slot="text">{{ error }}</p>
				</bbox-context-banner>
				<bbox-input
					:error="emailError"
					@keydown.enter.native="submitForgotPasswordForm"
					class="display-block margin-top-big font-size-big"
					name="email"
					label="E-mail"
					v-model="email"
				/>
				<bbox-button
					:loading="loading"
					@clickOrEnter="submitForgotPasswordForm"
					block
					class="margin-top-big font-size-big"
					>Me envie instruções</bbox-button
				>
			</div>

			<div class="display-flex flex-column" v-if="screen === 'instructions-sent'">
				<img src="/img/email-sent.svg" alt="E-mail enviado" class="margin-top-xxbig" />
				<h2
					class="font-size-huge semi-bold ink-base margin-top-xbig margin-bottom-big align-center"
				>
					Instruções enviadas
				</h2>
				<bbox-context-banner
					cardClasses="padding-vertical-normal padding-horizontal-big bg-red-lighter-3 red-base align-center font-size-big"
					ref="error"
				>
					<bbox-icon class="display-inline-block font-size-big" slot="icon">
						<icon-error />
					</bbox-icon>
					<p class="display-inline-block margin-0 font-size-big" slot="text">{{ error }}</p>
				</bbox-context-banner>
				<p class="ink-lighter-1 font-size-big align-center">
					Caso o endereço
					<strong>{{ email }}</strong> esteja cadastrado, um e-mail contendo instruções para
					redefinição de senha foi enviado e chegará em até 5 minutos.
					<br />
					<br />Não esqueça de checar sua caixa de spam.
				</p>
				<bbox-button
					:loading="loading"
					@clickOrEnter="() => submitNewPassword()"
					block
					class="margin-top-big font-size-big"
					>Reenviar e-mail</bbox-button
				>
				<bbox-button
					@clickOrEnter="screen = 'forgot-password'"
					link
					class="margin-top-big font-size-big"
					>Inserir e-mail novamente</bbox-button
				>
			</div>

			<div class="display-flex flex-column" v-if="screen === 'change-password'">
				<h2
					class="font-size-huge semi-bold ink-base margin-top-lg-xxbig margin-top-big margin-bottom-big align-center"
				>
					Redefinir senha
				</h2>
				<bbox-context-banner
					cardClasses="padding-vertical-normal padding-horizontal-big bg-red-lighter-3 red-base align-center font-size-big"
					ref="error"
				>
					<bbox-icon class="display-inline-block font-size-big" slot="icon">
						<icon-error />
					</bbox-icon>
					<p class="display-inline-block margin-0 font-size-big" slot="text">{{ error }}</p>
				</bbox-context-banner>
				<bbox-input
					:error="passwordError"
					@keydown.enter.native="submitChangePassword"
					type="password"
					class="margin-top-normal display-block font-size-big"
					name="password"
					label="Nova senha"
					v-model="password"
				/>
				<bbox-input
					:error="confirmPasswordError"
					type="password"
					class="margin-top-normal display-block font-size-big"
					name="confirmPassword"
					label="Nova senha (confirmar)"
					v-model="confirmPassword"
				/>
				<bbox-button
					:loading="loading"
					@clickOrEnter="submitChangePassword"
					block
					class="margin-top-big font-size-big"
					>Pronto</bbox-button
				>
			</div>

			<div class="display-flex flex-column" v-if="screen === 'password-changed-success'">
				<h2
					class="font-size-huge semi-bold ink-base margin-top-lg-xxbig margin-top-big margin-bottom-big align-center"
				>
					Senha redefinida
				</h2>
				<bbox-context-banner
					cardClasses="padding-vertical-normal padding-horizontal-big bg-red-lighter-3 red-base align-center font-size-big"
					ref="error"
				>
					<bbox-icon class="display-inline-block font-size-big" slot="icon">
						<icon-error />
					</bbox-icon>
					<p class="display-inline-block margin-0 font-size-big" slot="text">{{ error }}</p>
				</bbox-context-banner>
				<p class="ink-lighter-1 font-size-big align-center">Senha alterada com sucesso.</p>
				<bbox-button
					:loading="loading"
					@clickOrEnter="submitLogin"
					block
					class="margin-top-big font-size-big"
					>Entrar</bbox-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import BboxInput from '@bossabox-code/design-system/components/Input.vue';
import BboxButton from '@bossabox-code/design-system/components/Button.vue';
import BboxIcon from '@bossabox-code/design-system/components/Icon.vue';
import IconBossabox from '@bossabox-code/design-system/icons/icon-bossabox.svg';
import IconError from '@bossabox-code/design-system/icons/icon-error.svg';
import BboxContextBanner from '@bossabox-code/design-system/components/ContextBanner.vue';
import { mapActions } from 'vuex';

export default {
	name: 'app-login',
	components: {
		BboxInput,
		BboxButton,
		BboxIcon,
		IconBossabox,
		IconError,
		BboxContextBanner
	},
	data() {
		return {
			screen: 'forgot-password',
			error: '',
			email: '',
			password: '',
			confirmPassword: '',
			passwordResetToken: '',
			loginToken: '',
			loading: false,
			emailError: '',
			passwordError: '',
			confirmPasswordError: ''
		};
	},
	methods: {
		...mapActions('auth', ['login', 'getUser', 'forgotPassword', 'changePassword']),
		async submitForgotPasswordForm() {
			if (this.loading) return;
			if (!this.email) {
				this.emailError = 'Lembre-se de preencher os campos';
				return;
			}
			this.emailError = '';

			try {
				this.loading = true;
				this.$refs.error.hideContextBanner();
				await this.forgotPassword(this.email);
				this.loading = false;
				this.screen = 'instructions-sent';
			} catch (err) {
				this.loading = false;
				this.error = 'Ocorreu um erro, tente novamente!';
				this.$refs.error.showContextBanner();
			}
		},
		async submitNewPassword() {
			try {
				this.loading = true;
				this.$refs.error.hideContextBanner();
				await this.forgotPassword(this.email);
				this.loading = false;

				this.$toasts.show({
					message: 'E-mail enviado novamente com sucesso',
					description:
						'O e-mail chegará em até 5 minutos. Não esqueça de checar sua caixa de spam.',
					timeout: 3000,
					type: 'success'
				});
			} catch (err) {
				this.loading = false;
				this.error = 'Ocorreu um erro, tente novamente!';
				this.$refs.error.showContextBanner();
			}
		},
		async submitChangePassword() {
			if (this.loading) return;
			if (!this.password) {
				this.passwordError = 'Lembre-se de preencher os campos';
				return;
			}
			if (this.password.length < 8) {
				this.passwordError = 'A senha deve ter pelo menos 8 caracteres';
				return;
			}
			this.passwordError = '';
			if (!this.confirmPassword) {
				this.confirmPasswordError = 'Lembre-se de preencher os campos';
				return;
			}
			if (this.password !== this.confirmPassword) {
				this.confirmPasswordError = 'Senhas precisam ser iguais';
				return;
			}
			this.confirmPasswordError = '';
			try {
				this.loading = true;
				this.$refs.error.hideContextBanner();
				const loginToken = await this.changePassword({
					password: this.password,
					tokenResetPassword: this.passwordResetToken
				});
				this.loginToken = loginToken;
				this.screen = 'password-changed-success';
				this.loading = false;
			} catch (err) {
				this.loading = false;
				this.error = 'Ocorreu um erro, tente novamente!';
				this.$refs.error.showContextBanner();
			}
		},
		async submitLogin() {
			try {
				await this.getUser({ token: this.loginToken });
				this.redirectAfterLogin();
			} catch (err) {
				if (err.code && err.code == 'INVALID_ROLE') {
					this.$toasts.show({
						message: err.message,
						timeout: 3000,
						type: 'danger'
					});
				}
			}
		},
		redirectAfterLogin() {
			return this.$router.push('/');
		}
	},
	mounted() {
		if (this.$route.params.passwordResetToken) {
			const { passwordResetToken } = this.$route.params;
			this.passwordResetToken = passwordResetToken;
			this.screen = 'change-password';
		}
	}
};
</script>

<style scoped lang="scss">
.container-box {
	background: #ffffff;
	min-height: 100vh;
	align-items: center;
}
.forgot-password-box {
	width: 380px;
	@media (max-width: 767px) {
		width: 90%;
	}
}
.logo {
	font-size: 5.4rem;
}
</style>
