import amplitude from 'amplitude-js';

let amplitudeClientAdminInstance;
let initialized = false;

let amplitudeAdminApiKey = '';

if (
	process.env.VUE_APP_URL == 'https://admin.bossabox.com' ||
	process.env.VUE_APP_URL == 'https://dev.admin.bossabox.com'
) {
	amplitudeAdminApiKey = process.env.VUE_APP_AMPLITUDE_ADMIN_API_KEY;
}

export const trackingService = {
	initialize(user, anonymous = false) {
		const options = {
			saveEvents: amplitudeAdminApiKey.length == 32 ? true : false
		};
		amplitudeClientAdminInstance = amplitude.getInstance('Admin');

		if (anonymous) {
			amplitudeClientAdminInstance.init(amplitudeAdminApiKey, null, options);
		} else {
			amplitudeClientAdminInstance.init(amplitudeAdminApiKey, user._id, options);
			this.setUserProperties(user);
		}

		initialized = true;
		return;
	},

	logAdminEvent(eventName, eventProperties) {
		if (!amplitudeClientAdminInstance && amplitudeAdminApiKey.length == 32)
			throw new Error('AmplitudeClient not initialized');

		amplitudeClientAdminInstance.logEvent(eventName, eventProperties);
	},

	setUserProperties(user) {
		if (!amplitudeClientAdminInstance && amplitudeAdminApiKey.length == 32)
			throw new Error('AmplitudeClient not initialized');

		if (
			user.email ||
			(user.roles && user.roles.length)
		) {
			const userProperties = {
				email: user.email,
				roles: user.roles,
			};
			amplitudeClientAdminInstance.setUserProperties(userProperties);
		}
	},

	isInitialized() {
		if (
			!amplitudeClientAdminInstance
		)
			return false;
		return initialized;
	}
};

let installed = false;

const install = Vue => {
	if (installed) return;

	Object.defineProperties(Vue.prototype, {
		$trackingService: {
			get() {
				return trackingService;
			}
		}
	});

	installed = true;
};

export default { install };
