<template>
	<b-modal ref="confirmCancelCriteria" tiny>
		<h3 slot="header" class="semi-bold ink-base margin-0">Cancelar critério e evidências</h3>

		<p class="font-size-big ink-lighter-1 margin-0">
			Ao cancelar, você perderá informações. Tem certeza que deseja cancelar?
		</p>

		<section slot="footer" class="display-flex flex-wrap justify-content-around justify-content-md-end">
			<b-button class="font-size-big" link color="gray" @clickOrEnter="hideModal()">
				Voltar
			</b-button>

			<b-button class="font-size-big margin-left-big" danger @clickOrEnter="handleCancelClick()">
				Cancelar
			</b-button>
		</section>
	</b-modal>
</template>

<script>
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';

export default {
	name: 'app-confirm-cancel-criterias-modal',
	components: {
		BModal,
		BButton,
	},
	data() {
		return {};
	},
	methods: {
		showModal() {
			this.$refs.confirmCancelCriteria.showModal();
		},
		hideModal() {
			this.$refs.confirmCancelCriteria.hideModal();
		},
		handleCancelClick() {
			this.hideModal();
			this.$emit('onCancelButtonClicked');
		},
	},
};
</script>
