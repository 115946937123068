import { bossaBoxApi } from './api';

export const challengesService = {
	async getChallenges(role, state, page, limit) {
		let url = `/admin/challenges/instances?role=${role}&state=${state}`;
		if (page) url += `&page=${page}`;
		if (limit) url += `&limit=${limit}`;
		const challenges = (await bossaBoxApi.get(url)).data;
		return challenges;
	},
	async getChallengesTotals(role) {
		let url = `/admin/challenges/instances/all?role=${role}`;
		const challengesTotals = (await bossaBoxApi.get(url)).data;
		return challengesTotals;
	},
	async updateChallengeInstance(challengeInstance) {
		const challenge = (
			await bossaBoxApi.patch(
				`/admin/challenges/instances/${challengeInstance._id}`,
				challengeInstance
			)
		).data;
		return challenge;
	}
};

let installed = false;

const install = Vue => {
	if (installed) return;

	Object.defineProperties(Vue.prototype, {
		$challengesService: {
			get() {
				return challengesService;
			}
		}
	});

	installed = true;
};

export default { install };
