import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import InternalAccounts from '../views/InternalAccounts.vue';
import SuccessManagerAccounts from '../views/SuccessManagerAccounts.vue';
import RecruiterAccounts from '../views/RecruiterAccounts.vue';
// import FrontEndChallenges from '../views/ReviewChallenges/FrontEndChallenges.vue';
// import BackEndChallenges from '../views/ReviewChallenges/BackEndChallenges.vue';
// import ProductDesignerChallenges from '../views/ReviewChallenges/ProductDesignerChallenges.vue';
// import ProductManagerChallenges from '../views/ReviewChallenges/ProductManagerChallenges.vue';
import Challenges from '../views/ReviewChallenges/Challenges.vue';
import AllocatedProlancers from '../views/AllocatedProlancers.vue';
import Recruitment from '../views/Recruitment/Recruitment.vue';
import AccrualPayments from '../views/Finance/AccrualPayments.vue';
import Payments from '../views/Finance/Payments.vue';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'login',
			component: Login,
			meta: {
				title: 'Login'
			}
		},
		{
			path: '/logout',
			name: 'logout',
			component: Logout
		},
		{
			path: '/forgot-password/:passwordResetToken?',
			name: 'forgot-password',
			component: ForgotPassword,
			meta: {
				title: 'Recuperação de senha'
			}
		},
		/*
		 * TODO: se algum perfil de admin não tiver a permissão VIEW_ALL_ADMINS,
		 * um método redirectPermissionBased deve ser criado
		 */
		{
			path: '/accounts/internal',
			name: 'InternalAccounts',
			component: InternalAccounts,
			meta: {
				title: 'Contas Internas',
				requiresAuth: true,
				requiredPermission: 'VIEW_ALL_ADMINS'
			}
		},
		{
			path: '/accounts/success-manager',
			name: 'SuccessManagerAccounts',
			component: SuccessManagerAccounts,
			meta: {
				title: 'Gerentes de Sucesso',
				requiresAuth: true,
				requiredPermission: 'VIEW_ALL_USERS'
			}
		},
		{
			path: '/accounts/recruiter',
			name: 'RecruiterAccounts',
			component: RecruiterAccounts,
			meta: {
				title: 'Recrutador(a)',
				requiresAuth: true,
				requiredPermission: 'VIEW_ALL_USERS'
			}
		},
		{
			path: '/challenges/review/:challengeType',
			name: 'ReviewChallenges',
			component: Challenges,
			meta: {
				title: 'Avaliar Desafios',
				requiresAuth: true,
				requiredPermission: 'VIEW_CHALLENGES_SENT_BY_PROLANCERS'
			}
		},
		{
			path: '/prolancers/allocated',
			name: 'AllocatedProlancers',
			component: AllocatedProlancers,
			meta: {
				title: 'Alocações',
				requiresAuth: true,
				requiredPermission: 'VIEW_ALLOCATED_PROLANCERS'
			}
		},
		{
			path: '/recruitment/custom-review',
			name: 'Recruitment',
			component: Recruitment,
			meta: {
				title: 'Recrutamento',
				requiresAuth: true,
				requiredPermission: 'CREATE_CUSTOM_REVIEW'
			}
		},
		{
			path: '/finance/accrual-payments',
			name: 'AccrualPayments',
			component: AccrualPayments,
			meta: {
				title: 'Financeiro',
				requiresAuth: true,
				requiredPermission: 'VIEW_ACCRUAL_PAYMENTS'
			}
		},
		{
			path: '/finance/invoices',
			name: 'Invoices',
			component: Payments,
			meta: {
				title: 'Notas Fiscais',
				requiresAuth: true,
				requiredPermission: 'VIEW_ALL_PRODUCTS_PAYMENTS'
			}
		}

		// {
		// 	path: '/challenges/review/front-end',
		// 	name: 'FrontEndChallenges',
		// 	component: FrontEndChallenges,
		// 	meta: {
		// 		title: 'Avaliar Desafios - Front End',
		// 		requiresAuth: true,
		// 		requiredRole: 'ADMINISTRATOR'
		// 	}
		// },
		// {
		// 	path: '/challenges/review/back-end',
		// 	name: 'BackEndChallenges',
		// 	component: BackEndChallenges,
		// 	meta: {
		// 		title: 'Avaliar Desafios - Back End',
		// 		requiresAuth: true,
		// 		requiredRole: 'ADMINISTRATOR'
		// 	}
		// },
		// {
		// 	path: '/challenges/review/product-manager',
		// 	name: 'ProductManagerChallenges',
		// 	component: ProductManagerChallenges,
		// 	meta: {
		// 		title: 'Avaliar Desafios - Gerente de Produto',
		// 		requiresAuth: true,
		// 		requiredRole: 'ADMINISTRATOR'
		// 	}
		// },
		// {
		// 	path: '/challenges/review/product-designer',
		// 	name: 'ProductDesignerChallenges',
		// 	component: ProductDesignerChallenges,
		// 	meta: {
		// 		title: 'Avaliar Desafios - Designer de Produto',
		// 		requiresAuth: true,
		// 		requiredRole: 'ADMINISTRATOR'
		// 	}
		// }
	]
});

router.beforeEach(async (to, from, next) => {
	// seta o título da página (qua aparece na barra do navegador)
	document.title = to.meta.title ? `BossaBox Admin | ${to.meta.title}` : `BossaBox Admin`;

	if (to.meta.requiresAuth) {
		const token = window.localStorage.getItem('userToken');
		if (token) {
			try {
				if (!store.state.auth.user._id) {
					await store.dispatch('auth/getUser', { token });
				}

				const loggedUser = await store.state.auth.user;

				if (
					!to.meta.requiredPermission ||
					(loggedUser.permissions.length > 0 &&
						loggedUser.permissions.includes(to.meta.requiredPermission))
				) {
					return next();
				}
				return next({ path: '/accounts/internal' });
			} catch (err) {
				return next({ path: '/', query: { redirect: to.fullPath } });
			}
		}
		return next({ path: '/', query: { redirect: to.fullPath } });
	} else {
		if (to.path === '/') {
			const token = window.localStorage.getItem('userToken');
			if (token) {
				try {
					await store.dispatch('auth/getUser', { token });
					return next({ path: '/accounts/internal' });
				} catch (err) {
					return next();
				}
			}
		}
		return next();
	}
});

export default router;
