<template>
	<div>
		<div class="login-box margin-horizontal-auto display-flex flex-column">
			<bbox-icon class="margin-horizontal-auto margin-top-xbig">
				<icon-bossabox class="logo"></icon-bossabox>
			</bbox-icon>

			<h2
				class="font-size-huge semi-bold ink-base margin-top-lg-xxbig margin-top-big margin-bottom-big align-center"
			>
				Iniciar sessão
			</h2>

			<bbox-context-banner
				cardClasses="padding-vertical-normal padding-horizontal-big bg-red-lighter-3 red-base align-center font-size-big"
				ref="error"
			>
				<bbox-icon class="display-inline-block font-size-big" slot="icon">
					<icon-error />
				</bbox-icon>
				<p class="display-inline-block margin-0 font-size-big" slot="text">{{ error }}</p>
			</bbox-context-banner>

			<bbox-input
				:error="emailError"
				@keydown.enter.native="submitForm"
				class="display-block margin-top-big font-size-big"
				name="email"
				type="email"
				placeholder="E-mail"
				v-model="email"
			/>
			<bbox-input
				:error="passwordError"
				@keydown.enter.native="submitForm"
				type="password"
				class="margin-top-normal display-block font-size-big"
				name="password"
				placeholder="Senha"
				v-model="password"
			/>

			<bbox-button
				link
				class="margin-top-normal font-size-big align-left padding-0"
				@clickOrEnter="goToForgotPassword"
				>Esqueceu a senha?</bbox-button
			>

			<bbox-button
				:loading="loading"
				@clickOrEnter="submitForm"
				block
				class="margin-vertical-big font-size-big"
				>Entrar</bbox-button
			>
		</div>
	</div>
</template>

<script>
import BboxInput from '@bossabox-code/design-system/components/Input.vue';
import BboxButton from '@bossabox-code/design-system/components/Button.vue';
import BboxIcon from '@bossabox-code/design-system/components/Icon.vue';
import IconBossabox from '@bossabox-code/design-system/icons/icon-bossabox.svg';
import IconError from '@bossabox-code/design-system/icons/icon-error.svg';
import BboxContextBanner from '@bossabox-code/design-system/components/ContextBanner.vue';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'app-login',
	components: {
		BboxInput,
		BboxButton,
		BboxIcon,
		IconBossabox,
		IconError,
		BboxContextBanner
	},
	computed: {
		...mapState('auth', ['user'])
	},
	data() {
		return {
			error: '',
			email: '',
			password: '',
			loading: false,
			emailError: '',
			passwordError: ''
		};
	},
	methods: {
		...mapActions('auth', ['login']),
		async submitForm() {
			if (this.loading) return;
			if (!this.email) {
				this.emailError = 'E-mail é obrigatório';
				return;
			}
			this.emailError = '';
			if (!this.password) {
				this.passwordError = 'Senha é obrigatória';
				return;
			}
			this.passwordError = '';
			try {
				this.loading = true;
				this.$refs.error.hideContextBanner();
				await this.login({ email: this.email, password: this.password });
				this.redirectAfterLogin();
				this.loading = false;
			} catch (err) {
				this.loading = false;
				if (err.code && err.code == 'INVALID_ROLE') {
					this.$toasts.show({
						message: err.message,
						timeout: 3000,
						type: 'danger'
					});
				} else {
					this.error = err.message ? err.message : 'Ocorreu um erro, tente novamente!';
					this.$refs.error.showContextBanner();
				}
			}
		},
		redirectAfterLogin() {
			if (this.user.roles[0] === 'ADMINISTRATOR') return this.$router.push('/accounts/internal');
			else this.user.roles[0] === 'FINANCIAL';
			return this.$router.push('/finance/accrual-payments');
		},
		goToForgotPassword() {
			this.$router.push('/forgot-password');
		}
	}
};
</script>

<style scoped lang="scss">
.login-box {
	width: 380px;
	@media (max-width: 767px) {
		width: 90%;
	}
}
.logo {
	font-size: 5.4rem;
}
</style>
