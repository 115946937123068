<template>
	<div>
		<admin-header :user="this.user" tabSelected="finance" subtabSelected="accrualPayments" />

		<div class="padding-horizontal-big padding-horizontal-xl-xxbig">
			<div
				class="page-info margin-horizontal-auto display-flex flex-row margin-vertical-xbig justify-content-between"
			>
				<span class="padding-left-lg-0 align-center align-left-sm font-size-huge ink-base semi-bold"
					>Exportar competência</span
				>
			</div>
			<div class="content">
				<div class="col-xl-6 margin-bottom-normal">
					<div class="row display-flex cols-gutter-normal">
						<div class="col-lg-6 col-12">
							<b-datepicker
								v-model="from"
								name="from"
								label="Mês inicial"
								class="margin-bottom-lg-0 margin-bottom-big"
								:error="errors.from"
							/>
						</div>
						<div class="col-lg-6 col-12">
							<b-datepicker v-model="to" name="to" label="Mês final" :error="errors.to" />
						</div>
					</div>
				</div>
				<p class="margin-top-0 ink-lighter-2 font-size-small">
					Os dias selecionados não interferem no resultado. Apenas os meses e anos serão utilizados.
				</p>
				<b-checkbox class="margin-vertical-normal" label="Contabilizar apenas pagamentos completos" v-model="completedOnly" />
				<b-button @clickOrEnter="exportAccrualPaymentsAsDownloadableCsv()">Exportar</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import AdminHeader from '../components/AdminHeader.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import BDatepicker from '@bossabox-code/design-system/components/Datepicker.vue';
import BCheckbox from '@bossabox-code/design-system/components/Checkbox.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
	components: {
		AdminHeader,
		BButton,
		BDatepicker,
		BCheckbox
	},
	data() {
		return {
			from: null,
			to: null,
			errors: {
				from: null,
				to: null
			},
			completedOnly: true
		};
	},
	computed: {
		...mapGetters('auth', ['user'])
	},
	methods: {
		...mapActions('payments', ['getAccrualPaymentsCsv']),
		async exportAccrualPaymentsAsDownloadableCsv() {
			if (!this.from) this.errors.from = 'Este campo é obrigatório';
			else this.errors.from = null;
			if (!this.to) this.errors.to = 'Este campo é obrigatório';
			else this.errors.to = null;

			if (!this.errors.from && !this.errors.to) {
				let from = `${this.from.getFullYear()}-${this.from.getMonth() + 1}`;
				let to = `${this.to.getFullYear()}-${this.to.getMonth() + 1}`;
				let csv = await this.getAccrualPaymentsCsv({ from, to, completedOnly: this.completedOnly });
				csv = encodeURI(`data:text/csv;charset=utf-8,${csv}`);
				var link = document.createElement('a');
				link.setAttribute('href', csv);
				link.setAttribute('download', `competencia_${from}_${to}.csv`);
				document.body.appendChild(link); // Required for FF
				link.click();
			}
		}
	}
};
</script>

<style scoped lang="scss">
.horizontal-scroll {
	@media (max-width: 1200px) {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		height: 100%;
	}
}
</style>
