import { authAdminApi, bossaBoxApi } from './api';

export const accountsService = {
	async getInternalAccounts() {
		const internalUsers = (await authAdminApi.get(`/admins/`)).data;
		return internalUsers;
	},
	async getSuccessManagerAccounts() {
		const successManagers = (await bossaBoxApi.get(`/admin/users?roles=success_manager`)).data;
		return successManagers;
	},
	async getRecruiterAccounts() {
		const recruiters = (await bossaBoxApi.get(`/admin/users?roles=recruiter`)).data;
		return recruiters;
	},
	async getAllocatedProlancers() {
		const allocatedProlancers = (await bossaBoxApi.get(`/admin/prolancers/allocated`)).data;
		return allocatedProlancers;
	},
	async getAllocatedProlancersContacts() {
		const allocatedProlancersContacts = (await bossaBoxApi.get(`/admin/prolancers/allocated/contacts`)).data;
		return allocatedProlancersContacts;
	},
	async createInternalAccount(accountObject) {
		const user = (await authAdminApi.post('/admins', accountObject)).data;
		if (!user._id) {
			throw new Error('Resposta inesperada do servidor');
		} else {
			return user;
		}
	},
	async createManagerAccount(accountObject) {
		const { user: manager } = (await bossaBoxApi.post('/admin/users', accountObject)).data;
		if (!manager._id) {
			throw new Error('Resposta inesperada do servidor');
		} else {
			return manager;
		}
	},
	async updateInternalAccount(accountObject, userId) {
		const internalAccount = (await authAdminApi.patch(`/admins/${userId}`, accountObject)).data;
		if (!internalAccount._id) {
			throw new Error('Resposta inesperada do servidor');
		} else {
			return { internalAccount };
		}
	},
	async updateManagerAccount(accountObject, userId) {
		const manager = (await bossaBoxApi.patch(`/admin/users/${userId}`, accountObject)).data;
		if (!manager._id) {
			throw new Error('Resposta inesperada do servidor');
		} else {
			return { manager };
		}
	},
	async deleteInternalAccount(userId) {
		await authAdminApi.delete(`/admins/${userId}`);
	},
	async deleteManagerAccount(userId) {
		await bossaBoxApi.delete(`/admin/users/${userId}`);
	}
};

let installed = false;

const install = Vue => {
	if (installed) return;

	Object.defineProperties(Vue.prototype, {
		$accountsService: {
			get() {
				return accountsService;
			}
		}
	});

	installed = true;
};

export default { install };
