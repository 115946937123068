<template>
	<div>
		<div
			v-if="loading"
			class="align-center font-size-xbig margin-top-big margin-top-lg-xbig display-block"
		>
			<span
				><b-icon spin><icon-refresh class="font-size-xhuge"/></b-icon
			></span>
		</div>
		<div v-else>
			<div v-if="haveChallenges" class="margin-top-big display-flex">
				<b-table
					ref="challengesTable"
					@loadPageTable="loadPageTable"
					:items="evaluatedChallenges"
					:fields="fields"
					:pageCount="totalPages"
					:currentPage="request.page"
					:hasPagination="hasPagination"
				>
					<template slot="prolancer" slot-scope="props">
						<div class="display-flex align-items-center">
							<b-avatar
								:name="props.item.fullName"
								:color="props.item.avatarColor"
								:avatarUrl="props.item.profilePicture"
								normal
							/>
							<span class="margin-left-normal font-size-big">{{ props.item.fullName }}</span>
						</div>
					</template>
					<template slot="status" slot-scope="props">
						<div class="display-flex align-items-center">
							<div v-if="props.item.status == 'approved'">
								<b-icon class="green-base"> <icon-check-circle-outline /></b-icon>
								<span class="green-base font-size-big"> Aprovado </span>
							</div>
							<div v-else>
								<b-icon class="red-base"> <icon-not-interested /></b-icon>
								<span class="red-base font-size-big"> Reprovado </span>
							</div>
						</div>
					</template>
					<template
						v-if="user.permissions.includes('VIEW_CHALLENGE_REVIEWS')"
						slot="reviewProlancer"
						slot-scope="props"
					>
						<div class="display-flex justify-content-between align-items-center">
							<b-button
								class="font-size-big"
								link
								color="blue-base regular"
								@clickOrEnter="showDetailsModal(props)"
								>Ver detalhes</b-button
							>
						</div>
					</template>
				</b-table>
			</div>
			<div v-else class="display-flex flex-column padding-top-xbig align-items-center">
				<img
					src="/img/supervised-group.svg"
					alt="Sem desafios verificados"
					class="supervised-group"
				/>
				<p class="ink-lighter-1 regular font-size-xxbig text-align-center">
					No momento, não existem Desafios verificados.
				</p>
			</div>

			<app-details-challenge-modal
				v-if="user.permissions.includes('VIEW_CHALLENGE_REVIEWS')"
				ref="detailsChallengeModal"
			/>
		</div>
	</div>
</template>

<script>
import BTable from '@bossabox-code/design-system/components/Table.vue';
import BAvatar from '@bossabox-code/design-system/components/Avatar.vue';
import BIcon from '@bossabox-code/design-system/components/Icon.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import AppDetailsChallengeModal from './DetailsChallengeModal';
import { mapGetters, mapActions } from 'vuex';

export default {
	components: {
		BTable,
		BAvatar,
		BIcon,
		BButton,
		AppDetailsChallengeModal
	},
	props: {
		role: String
	},
	data() {
		return {
			fields: [
				{
					key: 'prolancer',
					value: 'Prolancer'
				},
				{
					key: 'email',
					value: 'E-mail'
				},
				{
					key: 'phone',
					value: 'Telefone'
				},
				{
					key: 'deliveryDate',
					value: 'Data de Avaliação'
				},
				{
					key: 'status',
					value: 'Status'
				},
				{
					key: 'reviewProlancer',
					value: ' '
				}
			],
			hasPagination: true,
			request: {
				page: 1,
				limit: 10
			},
			evaluatedChallenges: [],
			totalPages: 0,
			haveChallenges: false,
			loading: false
		};
	},
	computed: {
		...mapGetters('auth', ['user'])
	},
	methods: {
		...mapActions('challenges', ['getEvaluatedChallenges']),
		showDetailsModal(props) {
			this.$refs.detailsChallengeModal.showModal(props.item);
		},
		async getChallenges() {
			const challenges = await this.getEvaluatedChallenges(this.request);
			for (let i = 0; i < challenges.challengeInstances.length; i++) {
				let challenge = challenges.challengeInstances[i];
				let challengeInstance = {
					...challenge,
					fullName: challenge.user.fullName,
					avatarColor: challenge.user.avatarColor,
					profilePicture: challenge.user.profilePicture,
					email: challenge.user.email,
					phone: challenge.user.phone
						? challenge.user.phone
								.replace(/\D+/g, '')
								.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
						: '',
					deliveryDate: this.formatDate(new Date(challenge.updatedAt)),
					_id: challenge._id,
					_userId: challenge.user._id
				};
				this.evaluatedChallenges.push(challengeInstance);
			}
			this.totalPages = challenges.totalPages;
		},
		formatDate(date) {
			const day = date.toLocaleString('default', { day: 'numeric' });
			const month = date.toLocaleString('default', { month: 'long' });
			const year = date.toLocaleString('default', { year: 'numeric' });
			return day + ' de ' + month + ', ' + year;
		},
		loadPageTable(page) {
			this.evaluatedChallenges = [];
			this.request.page = page;
			this.getChallenges();
		}
	},
	async mounted() {
		this.loading = true;
		this.request.role = this.role;
		await this.getChallenges();
		if (this.totalPages > 1) this.hasPagination = true;
		else this.hasPagination = false;
		if (this.evaluatedChallenges && this.evaluatedChallenges.length > 0) this.haveChallenges = true;
		this.loading = false;
	}
};
</script>
