import { financesService } from '../../services/finances.service';
import { TYPES } from './mutations';

export default {
	async getAccrualPaymentsCsv({ commit }, { from, to, completedOnly }) {
		const accrualPayments = await financesService.getAccrualPayments(from, to, completedOnly);
		commit(TYPES.SET_ACCRUAL_PAYMENTS, { accrualPayments });
		return accrualPayments.csv;
	},
	async getPayments({ commit }, { statuses }) {
		const payments = await financesService.getPayments(statuses);
		commit(TYPES.SET_PAYMENTS, { payments });
		return payments;
	},
	// eslint-disable-next-line no-empty-pattern
	async downloadPayments({}, { filter }) {
		const payments = await financesService.downloadPayments(filter);
		return payments;
	},
	async getFilteredPayments({ commit }, { filter }) {
		const payments = await financesService.getFilteredPayments(filter);
		commit(TYPES.SET_PAYMENTS, { payments });
		return payments;
	},
	// eslint-disable-next-line no-empty-pattern
	async releasePayment({}, { paymentId, paymentInfo }) {
		await financesService.releasePayment(paymentId, paymentInfo);
	},
	// eslint-disable-next-line no-empty-pattern
	async rejectInvoice({}, { paymentId, paymentInfo }) {
		await financesService.rejectInvoice(paymentId, paymentInfo);
	}
};
