import axios from 'axios';
import { bossaBoxApi } from './api';

export const uploadService = {
	async requestUpload(filetype) {
		const response = await bossaBoxApi.post('/admin/uploads', { filetype });
		if (response.status === 201) return response.data;
		return response;
	},
	async upload(url, file) {
		const config = {
			headers: {
				'Content-Type': file.type
			}
		};
		return await axios.put(url, file, config);
	}
};

let installed = false;

const install = Vue => {
	if (installed) return;

	Object.defineProperties(Vue.prototype, {
		$uploadService: {
			get() {
				return uploadService;
			}
		}
	});

	installed = true;
};

export default { install };
