import { authApi, authAdminApi } from './api';
import { getRolesNames } from '../utils/roles';
import ApiError from './ApiError';

export const authService = {
	async login(email, password) {
		const { token } = (await authApi.post('/admin/auth/login', { email, password })).data;
		if (window.localStorage) window.localStorage.setItem('userToken', token);
		const user = (await authAdminApi.get('/admins/profile/me')).data;
		if (user.roles[0] !== 'FINANCIAL' && user.roles[0] !== 'ADMINISTRATOR') {
			if (window.localStorage) window.localStorage.removeItem('userToken');
			throw new ApiError(
				`Ainda não é possível fazer login com esse tipo de conta ${getRolesNames(user.roles[0])}.`,
				'INVALID_ROLE'
			);
		}
		return { user, token };
	},
	async getUser() {
		const user = (await authAdminApi.get('/admins/profile/me')).data;
		if (user.roles[0] !== 'FINANCIAL' && user.roles[0] !== 'ADMINISTRATOR') {
			if (window.localStorage) window.localStorage.removeItem('userToken');
			throw new ApiError(
				`Ainda não é possível fazer login com esse tipo de conta ${getRolesNames(user.roles[0])}.`,
				'INVALID_ROLE'
			);
		}
		return user;
	},
	async forgotPassword(email) {
		const response = (await authApi.post('/admin/auth/forgot', { email })).data;
		return response;
	},
	async changePassword(password, tokenResetPassword) {
		const response = (await authApi.post(`/admin/auth/forgot/${tokenResetPassword}`, { password }))
			.data;
		return response;
	}
};

let installed = false;

const install = Vue => {
	if (installed) return;

	Object.defineProperties(Vue.prototype, {
		$authService: {
			get() {
				return authService;
			}
		}
	});

	installed = true;
};

export default { install };
