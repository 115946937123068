<template>
	<b-modal
		ref="detailsChallengeModal"
		headerClasses="padding-horizontal-xbig border-bottom border-white-darker-4 padding-vertical-big"
		footerClasses="padding-horizontal-xbig padding-vertical-big"
		contentClasses="padding-0"
		huge
	>
		<div class="margin-0 semi-bold display-flex align-items-center" slot="header">
			<b-avatar
				:name="challengeInstance.fullName"
				:color="challengeInstance.avatarColor"
				:avatarUrl="challengeInstance.profilePicture"
				normal
			/>
			<span class="margin-left-normal font-size-big">{{ challengeInstance.fullName }}</span>
		</div>
		<div>
			<div class="padding-horizontal-xbig padding-vertical-big border-bottom border-white-darker-4">
				<p class="font-size-big semi-bold margin-0">Links</p>
				<div
					v-if="challengeLinks && challengeLinks.length > 0"
					class="padding-top-tiny display-flex"
				>
					<div class="padding-right-tiny" :key="info.id" v-for="info in challengeLinks">
						<b-button link class="font-size-big" @clickOrEnter="openLink(info.info)"
							><b-icon class="margin-right-tiny circle-icon"><icon-link /></b-icon>
							{{ info.label }}</b-button
						>
					</div>
				</div>
			</div>
			<div
				class="padding-horizontal-xbig padding-vertical-normal border-bottom border-white-darker-4"
			>
				<div>
					<p class="font-size-big semi-bold margin-bottom-tiny">Pontos positivos</p>
					<span class="font-size-big editor-wrapper" v-html="strength ? strength.text : ''" />
				</div>
				<div class="margin-top-big">
					<p class="font-size-big semi-bold margin-bottom-tiny">Pontos de melhorias</p>
					<span class="font-size-big editor-wrapper" v-html="improvement ? improvement.text : ''" />
				</div>
				<div class="margin-top-big" v-if="annotation && annotation.text != ''">
					<p class="font-size-big semi-bold margin-bottom-tiny">
						Observações para Gerentes de Sucesso
					</p>
					<p class="font-size-big editor-wrapper" v-html="annotation ? annotation.text : ''"></p>
				</div>
				<div class="margin-top-big">
					<p class="font-size-big semi-bold margin-bottom-tiny">
						Nota
					</p>
					<p class="font-size-big">{{ challengeInstance.finalGrade }}</p>
				</div>
			</div>
			<div class="padding-horizontal-xbig padding-top-big">
				<p class="font-size-big margin-0 semi-bold">Resultado</p>
			</div>
			<div class="border-bottom border-white-darker-4 padding-bottom-big">
				<div
					v-if="challengeInstance.status == 'approved'"
					class="display-flex align-items-center padding-top-normal padding-horizontal-xbig"
				>
					<b-icon class="green-base">
						<icon-check-circle-outline class="icon-size" />
					</b-icon>
					<p class="margin-0 padding-left-tiny font-size-big green-base">
						Prolancer Aprovado(a)
					</p>
				</div>
				<div
					v-else
					class="display-flex align-items-center padding-top-normal padding-horizontal-xbig"
				>
					<b-icon class="red-base"> <icon-not-interested class="icon-size"/></b-icon>
					<p class="red-base font-size-big padding-left-tiny margin-0">Prolancer Reprovado(a)</p>
				</div>
			</div>
		</div>
		<div
			slot="footer"
			class="display-flex flex-grow justify-content-between justify-content-md-end"
		>
			<b-button
				class="font-size-big"
				link
				color="gray"
				@clickOrEnter="$refs.detailsChallengeModal.hideModal()"
				>Fechar</b-button
			>
		</div>
	</b-modal>
</template>

<script>
import BAvatar from '@bossabox-code/design-system/components/Avatar.vue';
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import BIcon from '@bossabox-code/design-system/components/Icon.vue';

export default {
	name: 'app-details-challenge-modal',
	components: {
		BModal,
		BButton,
		BAvatar,
		BIcon
	},
	data() {
		return {
			challengeInstance: {},
			annotation: '',
			strength: '',
			improvement: ''
		};
	},
	computed: {
		challengeLinks() {
			if (!this.challengeInstance.info) return [];
			return this.challengeInstance.info.concat(this.challengeInstance.additionalInfo);
		}
	},
	methods: {
		showModal(challengeInstance) {
			this.challengeInstance = challengeInstance;
			this.annotation = challengeInstance.reviews[0].notes.find(obj => obj.title == 'Observações');
			this.strength = challengeInstance.reviews[0].notes.find(
				obj => obj.title == 'Pontos positivos'
			);
			this.improvement = challengeInstance.reviews[0].notes.find(
				obj => obj.title == 'Pontos de melhoria'
			);
			this.$refs.detailsChallengeModal.showModal();
		},
		hideModal() {
			this.$refs.detailsChallengeModal.hideModal();
		},
		toggleModal() {
			this.$refs.detailsChallengeModal.toggleModal();
		},
		openLink(link) {
			if (!link.match(/^https?:\/\//i)) {
				link = 'http://' + link;
			}
			window.open(link);
		}
	}
};
</script>
<style lang="scss" scoped>
.icon-size {
	width: 17px;
	height: 17px;
}

.editor-wrapper {
	overflow-wrap: break-word;
}
</style>
