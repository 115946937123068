// Ao utilizar esse mixin, é necessário criar um created setando as variáveis de acordo com as imagens que você deseja fazer upload

/* 
created() {
  this.image = { "profilePicture": '' };
  this.uploadingImage = { "profilePicture": false };
  this.uploadingImageError = { "profilePicture": '' };
}, 
*/

// No exemplo acima, profilePicture é o valor do ref do input utilizado para fazer upload da imagem

import uploadService from '@/services/upload.service.js';
import Vue from 'vue';

Vue.use(uploadService);

export default {
	data() {
		return {
			image: {},
			uploadingImage: {},
			uploadingImageError: {},
			imageSizes: {
				'1': 1024000,
				'2': 2048000,
				'3': 3072000,
				'4': 4096000,
				'5': 5120000
			}
		};
	},
	methods: {
		async uploadImage(file, filesRef, imageSize = 5) {
			const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];

			this.uploadingImage[filesRef] = '';
			this.uploadingImageError[filesRef] = '';

			if (!allowedTypes.includes(file.type)) {
				this.uploadingImageError[filesRef] = 'Por favor envie uma imagem PNG ou JPG';
				return;
			}

			if (file.size > this.imageSizes[imageSize]) {
				this.uploadingImageError[filesRef] = `Você atingiu o limite de ${imageSize} MB`;
				return;
			}

			this.uploadingImage[filesRef] = true;

			const { uploadUrl, fileUrl } = await this.$uploadService.requestUpload(file.type);
			let uploadStatus = 'failed';

			if (!uploadUrl) {
				this.$toasts.show({
					message: 'Falha ao requisitar URL para upload. Tente novamente mais tarde',
					timeout: 3000,
					type: 'danger'
				});
			} else {
				const response = await this.$uploadService.upload(uploadUrl, file);

				if (response.status === 200) {
					this.image[filesRef] = fileUrl;
					uploadStatus = 'success';
				} else {
					this.$toasts.show({
						message: 'Falha ao realizar upload. Tente novamente mais tarde',
						timeout: 3000,
						type: 'danger'
					});
				}
			}

			this.$refs[filesRef].value = '';
			this.uploadingImage[filesRef] = false;

			return uploadStatus;
		}
	}
};
