<template>
	<div>
		<div
			v-if="loading"
			class="align-center font-size-xbig margin-top-big margin-top-lg-xbig display-block"
		>
			<span
				><b-icon spin><icon-refresh class="font-size-xhuge"/></b-icon
			></span>
		</div>
		<div v-else>
			<div v-if="haveChallenges" class="margin-top-big display-flex">
				<b-table
					ref="challengesTable"
					@loadPageTable="loadPageTable"
					:items="notEvaluatedChallenges"
					:fields="fields"
					:pageCount="totalPages"
					:currentPage="request.page"
					:hasPagination="hasPagination"
				>
					<template slot="prolancer" slot-scope="props">
						<div class="display-flex align-items-center">
							<b-avatar
								:name="props.item.fullName"
								:color="props.item.avatarColor"
								:avatarUrl="props.item.profilePicture"
								normal
							/>
							<span class="margin-left-normal">{{ props.item.fullName }}</span>
						</div>
					</template>
					<template
						v-if="user.permissions.includes('REVIEW_CHALLENGES')"
						slot="reviewProlancer"
						slot-scope="props"
					>
						<div class="display-flex justify-content-between align-items-center">
							<b-button
								class="font-size-big"
								link
								color="blue"
								@clickOrEnter="showReviewProlancerModal(props)"
								><span class="regular">Avaliar prolancer</span></b-button
							>
						</div>
					</template>
				</b-table>
			</div>
			<div v-else class="display-flex padding-top-xbig flex-column align-items-center">
				<img
					src="/img/supervised-group.svg"
					alt="Sem desafios pendentes de verificação"
					class="supervised-group"
				/>
				<p class="ink-lighter-1 regular font-size-xxbig" style="text-align: center;">
					No momento, não existem Desafios pendentes de verificação.
				</p>
			</div>
			<app-review-challenge-modal
				v-if="user.permissions.includes('REVIEW_CHALLENGES')"
				ref="reviewChallengeModal"
				@reload="reload"
			/>
		</div>
	</div>
</template>

<script>
import BIcon from '@bossabox-code/design-system/components/Icon.vue';
import BTable from '@bossabox-code/design-system/components/Table.vue';
import BAvatar from '@bossabox-code/design-system/components/Avatar.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import AppReviewChallengeModal from './ReviewChallengeModal.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
	components: {
		BIcon,
		BTable,
		BAvatar,
		BButton,
		AppReviewChallengeModal
	},
	props: {
		role: String
	},
	computed: {
		...mapGetters('auth', ['user']),
		rolePage() {
			return this.role;
		}
	},
	data() {
		return {
			fields: [
				{
					key: 'prolancer',
					value: 'Prolancer'
				},
				{
					key: 'email',
					value: 'E-mail'
				},
				{
					key: 'phone',
					value: 'Telefone'
				},
				{
					key: 'deliveryDate',
					value: 'Data de Entrega'
				},
				{
					key: 'registrationDate',
					value: 'Data de Registro'
				},
				{
					key: 'reviewProlancer',
					value: ' '
				}
			],
			hasPagination: true,
			request: {
				page: 1,
				limit: 10
			},
			notEvaluatedChallenges: [],
			totalPages: undefined,
			haveChallenges: false,
			loading: false
		};
	},
	methods: {
		...mapActions('challenges', ['getNotEvaluatedChallenges']),
		showReviewProlancerModal(props) {
			this.$refs.reviewChallengeModal.showModal(props.item);
		},
		async getChallenges() {
			const challenges = await this.getNotEvaluatedChallenges(this.request);
			for (let i = 0; i < challenges.challengeInstances.length; i++) {
				let challenge = challenges.challengeInstances[i];
				let challengeInstance = {
					...challenge,
					fullName: challenge.user.fullName,
					avatarColor: challenge.user.avatarColor,
					profilePicture: challenge.user.profilePicture,
					email: challenge.user.email,
					phone: challenge.user.phone
						? challenge.user.phone
								.replace(/\D+/g, '')
								.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
						: '',
					deliveryDate: this.formatDate(new Date(challenge.createdAt)),
					registrationDate: this.formatDate(new Date(challenge.user.createdAt)),
					_userId: challenge.user._id
				};
				this.notEvaluatedChallenges.push(challengeInstance);
			}
			this.totalPages = challenges.totalPages;
		},
		formatDate(date) {
			const day = date.toLocaleString('default', { day: 'numeric' });
			const month = date.toLocaleString('default', { month: 'long' });
			const year = date.toLocaleString('default', { year: 'numeric' });
			return day + ' de ' + month + ', ' + year;
		},
		loadPageTable(page) {
			this.loading = true;
			this.notEvaluatedChallenges = [];
			this.request.page = page;
			this.getChallenges();
			this.loading = false;
		},
		reload() {
			this.notEvaluatedChallenges = [];
			this.getChallenges();
			this.$emit('reload');
		}
	},
	async mounted() {
		this.loading = true;
		this.request.role = this.rolePage;
		await this.getChallenges();
		if (this.totalPages > 1) this.hasPagination = true;
		else this.hasPagination = false;
		if (this.notEvaluatedChallenges && this.notEvaluatedChallenges.length > 0)
			this.haveChallenges = true;
		this.loading = false;
	}
};
</script>

<style>
.supervised-group {
	width: 110px;
	height: 128px;
}
</style>
