<template>
	<b-modal
		ref="reviewProlancerModal"
		headerClasses="padding-horizontal-xbig padding-vertical-big"
		footerClasses="padding-horizontal-xbig padding-vertical-big"
		contentClasses="padding-0"
		huge
	>
		<h3 class="margin-0 semi-bold" slot="header">Avaliar Prolancer</h3>
		<div>
			<div class="border-bottom border-white-darker-4 padding-bottom-big padding-horizontal-xbig">
				<bbox-context-banner
					cardClasses="display-flex padding-horizontal-normal justify-content-center align-items-center padding-vertical-normal bg-yellow-lighter-3 yellow-darker-1 align-center font-size-big"
					ref="infoBanner"
					:start-open="true"
				>
					<b-icon class="display-inline-block font-size-big" slot="icon"><icon-warning /></b-icon>
					<p class="display-inline-block margin-0 white-space-normal font-size-big" slot="text">
						{{ info }}
					</p>
				</bbox-context-banner>
			</div>
			<div class="padding-horizontal-xbig padding-vertical-big border-bottom border-white-darker-4">
				<p class="font-size-big semi-bold margin-0">Links</p>
				<div
					v-if="challengeLinks && challengeLinks.length > 0"
					class="padding-top-normal display-flex"
				>
					<div class="padding-right-tiny" :key="info.id" v-for="info in challengeLinks">
						<b-button link class="font-size-big" @clickOrEnter="openLink(info.info)"
							><b-icon class="margin-right-tiny circle-icon"><icon-link /></b-icon
							><span class="regular"> {{ info.label }}</span></b-button
						>
					</div>
				</div>
			</div>
			<div
				class="padding-horizontal-xbig padding-vertical-normal border-bottom border-white-darker-4"
			>
				<div>
					<p class="font-size-big semi-bold margin-bottom-tiny">Pontos positivos</p>
					<p class="white-space-normal margin-0 font-size-big regular ink-lighter-1">
						O(a) Prolancer avaliado irá receber essa informação.
					</p>
					<div
						class="editor font-size-big border margin-top-normal border-radius"
						:class="[
							{
								'border border-red-lighter-2 border-focus-red-base bg-red-lighter-4 bg-focus-red-lighter-3':
									errors.strength
							},
							{
								'border-white-darker-4 bg-white-darker-2': !errors.strength
							}
						]"
						@click="strenghtsEditor.focus()"
					>
						<editor-content
							class="margin-horizontal-normal"
							:class="[
								defaultStrenghtColorClass,
								{
									'red-lighter-2 has-error': errors.strength
								},
								{
									'ink-lighter-1': errors.strength
								}
							]"
							:editor="strenghtsEditor"
						></editor-content>
						<editor-menu-bubble
							class="menububble"
							:editor="strenghtsEditor"
							@hide="hideLinkMenu"
							v-slot="{ commands, isActive, getMarkAttrs, menu }"
						>
							<div
								class="menububble bg-white-base border border-white-darker-4 border-radius padding-vertical-xxtiny padding-horizontal-xxtiny"
								:class="{ 'is-active': menu.isActive }"
								:style="`left: ${menu.left}px; bottom: ${menu.bottom + 3}px;`"
							>
								<form
									class="menububble__form bg-white-base ink-base padding-vertical-xtiny padding-horizontal-tiny border-radius"
									v-if="linkMenuIsActive"
									@submit.prevent="setLinkUrl(commands.link, linkUrl)"
								>
									<input
										class="menububble__input border-none font-size-normal"
										style="outline: none"
										type="text"
										v-model="linkUrl"
										placeholder="https://"
										ref="linkInput"
										@keydown.esc="hideLinkMenu"
										@click.stop="$refs.linkInput.focus()"
									/>
									<button
										class="menububble__button bg-transparent border-none"
										style="cursor: pointer"
										@click="setLinkUrl(commands.link, null)"
										type="button"
									>
										<b-icon><icon-clear /></b-icon>
									</button>
								</form>

								<template v-else>
									<button
										class="menububble__button bg-white-base bg-hover-white-darker-2 font-size-big ink-lighter-1 border-none padding-vertical-xtiny padding-horizontal-tiny border-radius"
										@click.stop="showLinkMenu(getMarkAttrs('link'))"
										:class="{ 'is-active': isActive.link() }"
									>
										<span>
											<b-icon v-if="isActive.link()"><icon-link /></b-icon>
											<b-icon v-else><icon-link /></b-icon>
										</span>
									</button>
								</template>
							</div>
						</editor-menu-bubble>
						<div class="display-flex">
							<editor-menu-bar
								id="product-info-editor-menu-bar"
								class="margin-left-auto bg-white-base padding-tiny margin-right-normal margin-bottom-normal  border-radius border border-white-darker-4"
								:editor="strenghtsEditor"
								v-slot="{ commands, isActive, focused, focus }"
							>
								<div class="menubar">
									<button
										class="font-size-big padding-vertical-xtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button bold ink-lighter-1"
										:class="{ 'bg-white-darker-3!': isActive.bold() }"
										@click="commands.bold"
									>
										B
									</button>
									<button
										class="padding-vertical-xtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button italic  ink-lighter-1"
										:class="{ 'bg-white-darker-4!': isActive.italic() }"
										@click="commands.italic"
									>
										I
									</button>
									<button
										class="padding-vertical-xtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button underline  ink-lighter-1"
										:class="{ 'bg-white-darker-4!': isActive.underline() }"
										@click="commands.underline"
									>
										U
									</button>
									<button
										class="padding-vertical-xxtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button bullet-list ink-lighter-1"
										:class="{ 'bg-white-darker-4': isActive.bullet_list() }"
										@click="commands.bullet_list"
									>
										<b-icon flipX><icon-toc /></b-icon>
									</button>
								</div>
							</editor-menu-bar>
						</div>
					</div>
					<div class=" padding-top-tiny" v-if="errors.strength">
						<span class="font-size-big red-base">
							<b-icon>
								<icon-error />
							</b-icon>
							{{ errors.strength }}
						</span>
					</div>
				</div>
				<div class="margin-top-big">
					<p class="font-size-big semi-bold margin-bottom-tiny">Pontos de melhoria</p>
					<span class="white-space-normal font-size-big regular ink-lighter-1"
						>O(a) Prolancer avaliado irá receber essa informação.</span
					>
					<div
						class="editor font-size-big border margin-top-normal border-radius"
						:class="[
							{
								'border border-red-lighter-2 border-focus-red-base bg-red-lighter-4 bg-focus-red-lighter-3':
									errors.improvement
							},
							{
								'border-white-darker-4 bg-white-darker-2': !errors.improvement
							}
						]"
						@click="improvementEditor.focus()"
					>
						<editor-content
							class="margin-horizontal-normal"
							:class="[
								defaultImprovementColorClass,
								{
									'red-lighter-2 has-error': errors.improvement
								},
								{
									'ink-lighter-1': errors.strenghts
								}
							]"
							:editor="improvementEditor"
						></editor-content>
						<editor-menu-bubble
							class="menububble"
							:editor="improvementEditor"
							@hide="hideLinkMenu"
							v-slot="{ commands, isActive, getMarkAttrs, menu }"
						>
							<div
								class="menububble bg-white-base border border-white-darker-4 border-radius padding-vertical-xxtiny padding-horizontal-xxtiny"
								:class="{ 'is-active': menu.isActive }"
								:style="`left: ${menu.left}px; bottom: ${menu.bottom + 3}px;`"
							>
								<form
									class="menububble__form bg-white-base ink-base padding-vertical-xtiny padding-horizontal-tiny border-radius"
									v-if="linkMenuIsActive"
									@submit.prevent="setLinkUrl(commands.link, linkUrl)"
								>
									<input
										class="menububble__input border-none font-size-normal"
										style="outline: none"
										type="text"
										v-model="linkUrl"
										placeholder="https://"
										ref="linkInput"
										@keydown.esc="hideLinkMenu"
										@click.stop="$refs.linkInput.focus()"
									/>
									<button
										class="menububble__button bg-transparent border-none"
										style="cursor: pointer"
										@click="setLinkUrl(commands.link, null)"
										type="button"
									>
										<b-icon><icon-clear /></b-icon>
									</button>
								</form>

								<template v-else>
									<button
										class="menububble__button bg-white-base bg-hover-white-darker-2 font-size-big ink-lighter-1 border-none padding-vertical-xtiny padding-horizontal-tiny border-radius"
										@click.stop="showLinkMenu(getMarkAttrs('link'))"
										:class="{ 'is-active': isActive.link() }"
									>
										<span>
											<b-icon v-if="isActive.link()"><icon-link /></b-icon>
											<b-icon v-else><icon-link /></b-icon>
										</span>
									</button>
								</template>
							</div>
						</editor-menu-bubble>
						<div class="display-flex">
							<editor-menu-bar
								id="product-info-editor-menu-bar"
								class="margin-left-auto bg-white-base padding-tiny padding-tiny margin-bottom-normal margin-right-normal border-radius border border-white-darker-4"
								:editor="improvementEditor"
								v-slot="{ commands, isActive, focused, focus }"
							>
								<div class="menubar">
									<button
										class="font-size-big padding-vertical-xtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button bold ink-lighter-1"
										:class="{ 'bg-white-darker-3!': isActive.bold() }"
										@click="commands.bold"
									>
										B
									</button>
									<button
										class="padding-vertical-xtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button italic  ink-lighter-1"
										:class="{ 'bg-white-darker-4!': isActive.italic() }"
										@click="commands.italic"
									>
										I
									</button>
									<button
										class="padding-vertical-xtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button underline  ink-lighter-1"
										:class="{ 'bg-white-darker-4!': isActive.underline() }"
										@click="commands.underline"
									>
										U
									</button>
									<button
										class="padding-vertical-xxtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button bullet-list ink-lighter-1"
										:class="{ 'bg-white-darker-4': isActive.bullet_list() }"
										@click="commands.bullet_list"
									>
										<b-icon flipX><icon-toc /></b-icon>
									</button>
								</div>
							</editor-menu-bar>
						</div>
					</div>
					<div class=" padding-top-tiny" v-if="errors.improvement">
						<span class="font-size-big red-base">
							<b-icon>
								<icon-error />
							</b-icon>
							{{ errors.improvement }}
						</span>
					</div>
				</div>
				<div class="margin-top-big">
					<p class="white-space-normal font-size-big semi-bold margin-bottom-tiny">
						Observações para Gerentes de Sucesso
					</p>
					<span class="white-space-normal font-size-big regular ink-lighter-1"
						>O(a) Prolancer avaliado <strong>não</strong> irá receber essa informação.</span
					>
					<div
						class="editor font-size-big border margin-top-normal border-radius"
						:class="[
							{
								'border border-red-lighter-2 border-focus-red-base bg-red-lighter-4 bg-focus-red-lighter-3':
									errors.improvement
							},
							{
								'border-white-darker-4 bg-white-darker-2': !errors.improvement
							}
						]"
						@click="annotationEditor.focus()"
					>
						<editor-content
							class="margin-horizontal-normal"
							:class="[
								defaultAnnotationColorClass,
								{
									'red-lighter-2 has-error': errors.improvement
								},
								{
									'ink-lighter-1': errors.strenghts
								}
							]"
							:editor="annotationEditor"
						></editor-content>
						<editor-menu-bubble
							class="menububble"
							:editor="annotationEditor"
							@hide="hideLinkMenu"
							v-slot="{ commands, isActive, getMarkAttrs, menu }"
						>
							<div
								class="menububble bg-white-base border border-white-darker-4 border-radius padding-vertical-xxtiny padding-horizontal-xxtiny"
								:class="{ 'is-active': menu.isActive }"
								:style="`left: ${menu.left}px; bottom: ${menu.bottom + 3}px;`"
							>
								<form
									class="menububble__form bg-white-base ink-base padding-vertical-xtiny padding-horizontal-tiny border-radius"
									v-if="linkMenuIsActive"
									@submit.prevent="setLinkUrl(commands.link, linkUrl)"
								>
									<input
										class="menububble__input border-none font-size-normal"
										style="outline: none"
										type="text"
										v-model="linkUrl"
										placeholder="https://"
										ref="linkInput"
										@keydown.esc="hideLinkMenu"
										@click.stop="$refs.linkInput.focus()"
									/>
									<button
										class="menububble__button bg-transparent border-none"
										style="cursor: pointer"
										@click="setLinkUrl(commands.link, null)"
										type="button"
									>
										<b-icon><icon-clear /></b-icon>
									</button>
								</form>

								<template v-else>
									<button
										class="menububble__button bg-white-base bg-hover-white-darker-2 font-size-big ink-lighter-1 border-none padding-vertical-xtiny padding-horizontal-tiny border-radius"
										@click.stop="showLinkMenu(getMarkAttrs('link'))"
										:class="{ 'is-active': isActive.link() }"
									>
										<span>
											<b-icon v-if="isActive.link()"><icon-link /></b-icon>
											<b-icon v-else><icon-link /></b-icon>
										</span>
									</button>
								</template>
							</div>
						</editor-menu-bubble>
						<div class="display-flex">
							<editor-menu-bar
								id="product-info-editor-menu-bar"
								class="margin-left-auto bg-white-base padding-tiny padding-tiny margin-bottom-normal margin-right-normal border-radius border border-white-darker-4"
								:editor="annotationEditor"
								v-slot="{ commands, isActive, focused, focus }"
							>
								<div class="menubar">
									<button
										class="font-size-big padding-vertical-xtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button bold ink-lighter-1"
										:class="{ 'bg-white-darker-3!': isActive.bold() }"
										@click="commands.bold"
									>
										B
									</button>
									<button
										class="padding-vertical-xtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button italic  ink-lighter-1"
										:class="{ 'bg-white-darker-4!': isActive.italic() }"
										@click="commands.italic"
									>
										I
									</button>
									<button
										class="padding-vertical-xtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button underline  ink-lighter-1"
										:class="{ 'bg-white-darker-4!': isActive.underline() }"
										@click="commands.underline"
									>
										U
									</button>
									<button
										class="padding-vertical-xxtiny padding-horizontal-tiny bg-white-base border-none bg-hover-white-darker-2 menubar__button bullet-list ink-lighter-1"
										:class="{ 'bg-white-darker-4': isActive.bullet_list() }"
										@click="commands.bullet_list"
									>
										<b-icon flipX><icon-toc /></b-icon>
									</button>
								</div>
							</editor-menu-bar>
						</div>
					</div>
				</div>
				<div class="margin-top-big">
					<p class="font-size-big semi-bold margin-bottom-tiny">
						Nota
					</p>
					<span class="white-space-normal font-size-big regular ink-lighter-1"
						>O(a) Prolancer avaliado <strong>não</strong> irá receber essa informação.</span
					>
					<b-input
						class="display-flex margin-vertical-normal"
						name="grades"
						v-model="grade"
						:error="errors.grade"
						@input="errors.grade = ''"
						v-mask="'#.##'"
					/>
				</div>
			</div>
			<div class="padding-horizontal-xbig padding-vertical-big border-bottom border-white-darker-4">
				<p class="font-size-big margin-0 semi-bold">Resultado</p>
				<b-radio
					name="results"
					labelClasses="ink-base font-size-big"
					labelMajorClasses="display-flex margin-vertical-normal margin-right-normal"
					generalClasses="display-flex flex-direction-column"
					indexItem="result"
					:items="results"
					v-model="result"
					:error="errors.result"
					@input="errors.result = ''"
				/>
			</div>
		</div>
		<div
			slot="footer"
			class="display-flex flex-grow justify-content-between justify-content-md-end"
		>
			<b-button
				class="font-size-big margin-right-big md-margin-bottom-big"
				link
				color="gray"
				@clickOrEnter="cancel()"
				>Cancelar</b-button
			>
			<b-button @clickOrEnter="submitForm()" class="lg-margin-left-big font-size-big"
				>Finalizar avaliação</b-button
			>
		</div>
		<app-approve-challenge-modal
			:name="firstName"
			ref="approveChallengeModal"
			@updateChallengeInstance="updateChallenge"
		/>
		<app-disapprove-challenge-modal
			:name="firstName"
			ref="disapproveChallengeModal"
			@updateChallengeInstance="updateChallenge"
		/>
		<app-cancel-confirmation-modal ref="cancelConfirmationModal" @cancelOption="hideModal" />
	</b-modal>
</template>

<script>
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import BIcon from '@bossabox-code/design-system/components/Icon.vue';
import BInput from '@bossabox-code/design-system/components/Input.vue';
import BRadio from '@bossabox-code/design-system/components/Radio.vue';
import BboxContextBanner from '@bossabox-code/design-system/components/ContextBanner.vue';
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap';
import { Bold, Italic, Underline, ListItem, BulletList } from 'tiptap-extensions';
import CustomLink from '@/utils/customLink.js';
import AppApproveChallengeModal from './ApproveChallengeModal.vue';
import AppDisapproveChallengeModal from './DisapproveChallengeModal.vue';
import AppCancelConfirmationModal from './CancelConfirmationModal.vue';
import { mapActions } from 'vuex';
import VueMask from 'v-mask';

import Vue from 'vue';
Vue.use(VueMask);

export default {
	name: 'app-review-prolancer-modal',
	components: {
		BModal,
		BInput,
		BRadio,
		BButton,
		BIcon,
		BboxContextBanner,
		EditorContent,
		EditorMenuBar,
		EditorMenuBubble,
		AppApproveChallengeModal,
		AppDisapproveChallengeModal,
		AppCancelConfirmationModal
	},
	data() {
		return {
			props: {},
			firstName: '',
			result: '',
			grade: '',
			defaultStrenghtColorClass: 'ink-lighter-1',
			defaultImprovementColorClass: 'ink-lighter-1',
			defaultAnnotationColorClass: 'ink-lighter-1',
			strenghtsEditor: new Editor({
				extensions: [
					new ListItem(),
					new BulletList(),
					new Bold(),
					new Italic(),
					new Underline(),
					new CustomLink()
				],
				content: `Digite aqui...`,
				onUpdate: ({ getHTML }) => {
					const content = getHTML();
					if (content !== '<p>Digite aqui...</p>' && content !== '<p></p>') {
						this.defaultStrenghtColorClass = 'ink-base';
					} else {
						this.defaultStrenghtColorClass = 'ink-lighter-1';
					}
				},
				onFocus: () => {
					this.errors.strength = '';
					const content = this.strenghtsEditor.getHTML();
					if (content === '<p>Digite aqui...</p>') this.strenghtsEditor.setContent('', true);
				},
				onBlur: () => {
					const content = this.strenghtsEditor.getHTML();
					if (content === '<p></p>') this.strenghtsEditor.setContent('<p>Digite aqui...</p>', true);
				}
			}),
			improvementEditor: new Editor({
				extensions: [
					new ListItem(),
					new BulletList(),
					new Bold(),
					new Italic(),
					new Underline(),
					new CustomLink()
				],
				content: `Digite aqui...`,
				onUpdate: ({ getHTML }) => {
					const content = getHTML();
					if (content !== '<p>Digite aqui...</p>' && content !== '<p></p>') {
						this.defaultImprovementColorClass = 'ink-base';
					} else {
						this.defaultImprovementColorClass = 'ink-lighter-1';
					}
				},
				onFocus: () => {
					this.errors.improvement = '';
					const content = this.improvementEditor.getHTML();
					if (content === '<p>Digite aqui...</p>') this.improvementEditor.setContent('', true);
				},
				onBlur: () => {
					const content = this.improvementEditor.getHTML();
					if (content === '<p></p>')
						this.improvementEditor.setContent('<p>Digite aqui...</p>', true);
				}
			}),
			annotationEditor: new Editor({
				extensions: [
					new ListItem(),
					new BulletList(),
					new Bold(),
					new Italic(),
					new Underline(),
					new CustomLink()
				],
				content: `Digite aqui...`,
				onUpdate: ({ getHTML }) => {
					const content = getHTML();
					if (content !== '<p>Digite aqui...</p>' && content !== '<p></p>') {
						this.defaultAnnotationColorClass = 'ink-base';
					} else {
						this.defaultAnnotationColorClass = 'ink-lighter-1';
					}
				},
				onFocus: () => {
					const content = this.annotationEditor.getHTML();
					if (content === '<p>Digite aqui...</p>') this.annotationEditor.setContent('', true);
				},
				onBlur: () => {
					const content = this.annotationEditor.getHTML();
					if (content === '<p></p>')
						this.annotationEditor.setContent('<p>Digite aqui...</p>', true);
				}
			}),
			grades: [
				{
					name: '1',
					value: '1'
				},
				{
					name: '2',
					value: '2'
				},
				{
					name: '3',
					value: '3'
				},
				{
					name: '4',
					value: '4'
				},
				{
					name: '5',
					value: '5'
				}
			],
			results: [
				{
					name: 'Aprovar prolancer',
					value: 'Aprovado'
				},
				{
					name: 'Reprovar prolancer',
					value: 'Reprovado'
				}
			],
			linkUrl: '',
			linkMenuIsActive: false,
			errors: {},
			info: 'Algumas informações preenchidas nessa avaliação serão enviadas para o(a) Prolancer.'
		};
	},
	computed: {
		editorContent() {
			return this.editor.getHTML();
		},
		challengeLinks() {
			if (!this.props.info) return [];
			return this.props.info.concat(this.props.additionalInfo);
		}
	},
	methods: {
		...mapActions('challenges', ['updateChallengeInstance']),
		showModal(props) {
			this.errors = {};
			this.props = props;
			this.setFirstName();
			this.resetFields();

			this.$refs.reviewProlancerModal.showModal();
		},
		hideModal() {
			this.$refs.reviewProlancerModal.hideModal();
		},
		toggleModal() {
			this.$refs.reviewProlancerModal.toggleModal();
		},
		resetFields() {
			this.result = '';
			this.grade = '';
			this.strenghtsEditor.setContent('Digite aqui...', true);
			this.improvementEditor.setContent('Digite aqui...', true);
			this.annotationEditor.setContent('Digite aqui...', true);
		},
		createObjectToSend() {
			this.props.notes = [
				{
					title: 'Pontos positivos',
					text: this.strenghtsEditor.getHTML(),
					visibleToProlancer: true
				},
				{
					title: 'Pontos de melhoria',
					text: this.improvementEditor.getHTML(),
					visibleToProlancer: true
				},
				{
					title: 'Observações',
					text: this.annotationEditor.getHTML(),
					visibleToProlancer: false
				}
			];
			this.props.approved = this.result == 'Aprovado' ? true : false;
			this.props.grade = this.grade;
			this.props.user = {
				_id: this.props._userId,
			};
		},
		cancel() {
			this.$refs.cancelConfirmationModal.showModal();
		},
		validateForm() {
			this.errors = {};
			if (!this.grade) Vue.set(this.errors, 'grade', 'Digite uma nota');
			let grade = parseFloat(this.grade);
			if (grade < 1.0 || grade > 5.0)
				Vue.set(this.errors, 'grade', 'Digite um nota válida (entre 1.00 e 5.00)');
			if (!this.result) Vue.set(this.errors, 'result', 'Selecione um resultado');
			if (
				!this.strenghtsEditor.getHTML() ||
				this.strenghtsEditor.getHTML() == '<p>Digite aqui...</p>'
			)
				Vue.set(this.errors, 'strength', 'Digite os pontos fortes');
			if (
				!this.improvementEditor.getHTML() ||
				this.strenghtsEditor.getHTML() == '<p>Digite aqui...</p>'
			)
				Vue.set(this.errors, 'improvement', 'Digite os pontos de melhoria');

			if (Object.keys(this.errors).length == 0) return true;
		},
		submitForm() {
			if (this.validateForm()) {
				this.createObjectToSend();

				if (this.props.approved) this.$refs.approveChallengeModal.showModal(this.props);
				else this.$refs.disapproveChallengeModal.showModal(this.props);
			}
		},
		async updateChallenge(approved) {
			await this.updateChallengeInstance(this.props);
			if (approved) this.$refs.approveChallengeModal.hideModal();
			else this.$refs.disapproveChallengeModal.hideModal();

			this.$refs.reviewProlancerModal.hideModal();

			this.$toasts.show({
				message: `Prolancer ${approved ? 'aprovado' : 'reprovado'} com sucesso`,
				timeout: 3000,
				type: 'success'
			});
			this.$emit('reload');
		},
		openLink(link) {
			if (!link.match(/^https?:\/\//i)) {
				link = 'http://' + link;
			}
			window.open(link);
		},
		showLinkMenu(attrs) {
			this.linkUrl = attrs.href;
			this.linkMenuIsActive = true;
			this.$nextTick(() => {
				this.$refs.linkInput.focus();
			});
		},
		hideLinkMenu() {
			this.linkUrl = null;
			this.linkMenuIsActive = false;
		},
		setLinkUrl(command, url) {
			if (url) url = !/^https?:\/\//i.test(url) ? `https://${url}` : url;
			command({ href: url, target: '_blank' });
			this.hideLinkMenu();
			this.editor.focus();
		},
		setFirstName() {
			const arrayName = this.props.fullName.toLowerCase().split(' ');
			this.firstName = arrayName[0].charAt(0).toUpperCase() + arrayName[0].slice(1);
		}
	}
};
</script>
<style lang="scss" scoped>
@media (max-width: 978px) {
	.white-space-normal {
		white-space: normal;
	}
}

.menububble {
	position: absolute;
	transform: translateX(-50%);
	visibility: hidden;
	&.is-active {
		visibility: visible;
	}
}
.editor {
	cursor: text;
}

.editor .menubar__button {
	font-family: serif;
	font-size: 1.875rem;
	font-weight: bold;
}

.editor li p {
	margin: 0;
}
.editor {
	p,
	a {
		font-size: 1.6875rem;
		&:first-child {
			margin-top: 0;
		}
	}
}

.circle-icon {
	background: #e7edff;
	padding: 5px;
	border-radius: 50%;
}

@media (max-width: 768px) {
	flex-direction-column {
		flex-direction: column;
	}
}
</style>

<style lang="scss">
.ProseMirror {
	outline: none;
}

@media (max-width: 540px) {
	.flex-direction-column {
		flex-direction: column;
	}
}
</style>
