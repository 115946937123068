<template>
	<div>
		<admin-header :user="this.user" tabSelected="allocatedProlancers" />

		<div class="padding-horizontal-big padding-horizontal-xl-xxbig">
			<div
				class="page-info margin-horizontal-auto display-flex flex-row margin-vertical-xbig justify-content-between"
			>
				<span
					class="padding-left-lg-0 align-center margin-left-tiny align-left-sm font-size-huge ink-base semi-bold"
					>Listagem de alocações</span
				>
				<b-button
					class="export-data display-lg-block margin-left-normal margin-left-md-auto margin-right-tiny font-size-big margin-top-big margin-top-md-0"
					@clickOrEnter="exportAllocatedProlancersAsDownloadableCsv()"
				>
					Exportar
				</b-button>
			</div>
			<div v-if="items.length > 0">
				<div class="margin-top-big display-flex horizontal-scroll">
					<b-table :items="items" :fields="fields" auto>
						<template slot="fullName" slot-scope="props">
							<div class="display-flex align-items-center">
								<b-avatar
									:name="props.item.fullName"
									:color="props.item.avatarColor"
									:avatarUrl="props.item.profilePicture"
									normal
								/>
								<span class="margin-left-normal font-size-big">{{ props.item.fullName }}</span>
							</div>
						</template>
						<template slot="projects" slot-scope="props">
							<div class="font-size-big" v-html="mapProjects(props.item.projects)"></div>
						</template>
					</b-table>
				</div>
			</div>
			<div v-else class="display-flex flex-column padding-top-xbig align-items-center">
				<img src="/img/supervised-group.svg" alt="Sem prolancers alocados" />
				<p class="ink-lighter-1 regular font-size-xxbig text-align-center">
					No momento, não existem Alocações.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import BButton from '@bossabox-code/design-system/components/Button.vue';
import BTable from '@bossabox-code/design-system/components/Table.vue';
import BAvatar from '@bossabox-code/design-system/components/Avatar.vue';
import { mapGetters, mapActions } from 'vuex';
import { getRolesNames } from '../utils/roles.js';
import { accountsService } from '../services/accounts.service';
import AdminHeader from './components/AdminHeader.vue';

export default {
	components: {
		BButton,
		BTable,
		BAvatar,
		AdminHeader
	},
	data() {
		return {
			fields: [
				{
					key: 'fullName',
					value: 'Nome'
				},
				{
					key: 'email',
					value: 'E-mail'
				},
				{
					key: 'role',
					value: 'Função'
				},
				{
					key: 'totalBiweeklyPayment',
					value: 'Remuneração total por quinzena'
				},
				{
					key: 'projects',
					value: 'Projetos'
				}
			],
			items: []
		};
	},
	computed: {
		...mapGetters('auth', ['user'])
	},
	methods: {
		...mapActions('accounts', ['getAllocatedProlancers']),
		async handleAllocatedProlancers() {
			this.items = [];
			const allocatedProlancers = await this.getAllocatedProlancers();
			for (let i = 0; i < allocatedProlancers.length; i++) {
				let prolancer = allocatedProlancers[i];
				let prolancerTable = {
					...prolancer,
					role: getRolesNames(prolancer.role),
					profilePicture: !prolancer.profilePicture ? '' : prolancer.profilePicture,
					totalBiweeklyPayment: `R$ ${(prolancer.totalBiweeklyPayment || 0).toLocaleString(
						'pt-BR'
					)} `
				};
				this.items.push(prolancerTable);
			}
		},
		async exportAllocatedProlancersAsDownloadableCsv() {
			let csv = await accountsService.getAllocatedProlancersContacts();
			csv = encodeURI(`data:text/csv;charset=utf-8,${csv}`);
			var link = document.createElement('a');
			link.setAttribute('href', csv);
			link.setAttribute('download', `allocatedProlancers.csv`);
			document.body.appendChild(link); // Required for FF
			link.click();
		},
		mapProjects(projects) {
			return projects
				.map(
					project =>
						`<strong>${
							project.projectName
						}</strong>&nbsp;<span>(${project.deliverySuccessNames.join(', ')})</span>`
				)
				.join('<br/>');
		}
	},
	beforeMount() {
		this.handleAllocatedProlancers();
	}
};
</script>

<style scoped lang="scss">
.horizontal-scroll {
	@media (max-width: 1200px) {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		height: 100%;
	}
}

.page-info {
	align-items: center;
}

@media (max-width: 768px) {
	.page-info {
		flex-direction: column;
	}
	.page-info > div:first-child {
		flex-direction: column;
	}
	.page-info .export-data {
		width: -webkit-fill-available;
	}
}
</style>
