export default {
	released: {
		index: 1,
		status: 'Pagamento liberado',
		message: 'Aguardando repasse do pagamento para a conta bancária do Prolancer.',
		icon: '/img/icon-done.png',
		color: 'ink',
		titleColor: 'ink-base',
		titleBackground: 'bg-white-darker-3'
	},
	completed: {
		index: 2,
		status: 'Pagamento realizado',
		message: 'O pagamento foi realizado e já consta na conta bancária do Prolancer.',
		icon: '/img/icon-attach-money.png',
		color: 'blue',
		titleColor: 'blue-darker-2',
		titleBackground: 'bg-blue-lighter-4'
	},
	failed: {
		index: 2,
		status: 'Erro no pagamento',
		message:
			'O Prolancer possui dados bancários divergentes cadastrados na Bossabox e o repasse não foi concluído.',
		icon: '/img/icon-error.png',
		color: 'red',
		titleColor: 'red-darker-2',
		titleBackground: 'bg-red-lighter-4'
	}
};
