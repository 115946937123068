<template>
	<b-modal ref="settingsAccountModal" tiny>
		<h3 class="margin-0 semi-bold" slot="header">
			{{ this.edition ? 'Editar usuário' : 'Adicionar usuário' }}
		</h3>
		<div
			:style="{
				'--flag-default-position': flagDefaultPosition,
				'--flag-big-position': flagBigPosition
			}"
		>
			<div class="display-flex flex-wrap">
				<label
					for="profilePictureUpload"
					@click="uploadingImage.profilePicture ? $event.preventDefault() : null"
					style="cursor: pointer;"
				>
					<b-avatar
						:name="account.fullName"
						:avatarUrl="image.profilePicture"
						:color="account.avatarColor"
						big
						@click="uploadingImage.profilePicture ? $event.preventDefault() : null"
						style="cursor: pointer;"
					/>
				</label>
				<div style="flex-grow: 1" class="margin-top-0 margin-left-normal">
					<div class="display-flex flex-grow" style="height: 100%; align-items: center;">
						<div class="margin-right-auto">
							<p class="semi-bold margin-0 font-size-big display-none display-lg-block">
								Foto de perfil
							</p>
							<p
								v-if="uploadingImageError.profilePicture"
								class="display-none display-lg-block red-base font-size-big margin-top-tiny margin-bottom-0"
							>
								<b-icon>
									<icon-error />
								</b-icon>
								{{ uploadingImageError.profilePicture }}
							</p>
						</div>
						<div class="margin-left-auto display-flex" style="flex-direction: column;">
							<label
								for="profilePictureUpload"
								@click="uploadingImage.profilePicture ? $event.preventDefault() : null"
								style="cursor: pointer;"
								class="semi-bold margin-top-0 margin-bottom-0 align-right font-size-big blue-base"
							>
								<span v-if="!uploadingImage.profilePicture && !image.profilePicture">
									<b-icon> <icon-cloud-upload /> </b-icon> Escolher
								</span>
								<b-icon v-if="uploadingImage.profilePicture" spin>
									<icon-refresh />
								</b-icon>
							</label>
							<p
								@click="image.profilePicture = ''"
								style="cursor: pointer;"
								class="semi-bold margin-top-0 margin-bottom-0 margin-left-auto align-right font-size-big blue-base"
							>
								<span v-if="!uploadingImage.profilePicture && image.profilePicture">
									<b-icon> <icon-clear /></b-icon> Remover
								</span>
							</p>
							<label
								for="profilePictureUpload"
								@click="uploadingImage.profilePicture ? $event.preventDefault() : null"
								style="cursor: pointer;"
								class="semi-bold margin-top-tiny margin-bottom-0 margin-left-auto align-right font-size-big blue-base"
							>
								<span v-if="!uploadingImage.profilePicture && image.profilePicture">
									<b-icon> <icon-autorenew /> </b-icon> Trocar
								</span>
							</label>
						</div>
					</div>
				</div>
				<input
					tabindex="-1"
					id="profilePictureUpload"
					type="file"
					ref="profilePicture"
					style="width: 0; height: 0; opacity: 0;"
					@change="uploadImage($event.target.files[0], 'profilePicture', 5)"
				/>
				<p
					v-if="uploadingImageError.profilePicture"
					class="display-lg-none red-base font-size-big margin-top-tiny margin-bottom-0"
				>
					<b-icon>
						<icon-error />
					</b-icon>
					{{ uploadingImageError.profilePicture }}
				</p>
			</div>

			<b-select
				:error="errors.userType"
				@input="deleteProperty('userType')"
				name="user-type"
				class="margin-top-big"
				inputPlaceholder="Selecionar o tipo de usuário"
				:options="accountTypes"
				v-model="account.userType"
				searchable
			>
				<div slot="label">
					<p class="font-size-big ink semi-bold margin-bottom-normal margin-top-0">
						Tipo de usuário*
					</p>
				</div>
			</b-select>

			<b-select
				:error="errors.permission"
				@input="deleteProperty('permission')"
				v-model="account.permission"
				name="permission"
				class="margin-top-big"
				inputPlaceholder="Selecionar o nível de permissionamento"
				:options="permissionLevel"
				@change="permissionChange"
				searchable
			>
				<div slot="label">
					<p class="font-size-big ink semi-bold margin-bottom-normal margin-top-0">
						Permissionamento*
					</p>
				</div>
			</b-select>

			<b-text-input
				:error="errors.fullName"
				@input="deleteProperty('fullName')"
				class="margin-top-big"
				name="fullName"
				placeholder="Escreva seu nome"
				v-model="account.fullName"
			>
				<div slot="label">
					<p class="font-size-big ink semi-bold margin-top-0 margin-bottom-normal">
						Nome Completo*
					</p>
				</div>
			</b-text-input>

			<b-text-input
				:error="errors.email"
				@input="deleteProperty('email')"
				class="margin-top-big"
				name="email"
				placeholder="nome@email.com"
				v-model="account.email"
			>
				<div slot="label">
					<p class="font-size-big ink semi-bold margin-top-0 margin-bottom-normal">E-mail*</p>
				</div>
			</b-text-input>

			<div class="container-input-phone">
				<span class="flag-icon" :class="flagClass" />
				<b-text-input
					:error="errors.phone"
					@input="errors.phone = ''"
					class="margin-top-big"
					name="phone"
					v-model="account.phone"
					v-mask="'(##) #####-####'"
					label="Telefone"
					placeholder="(__)_____-_____"
					inputClasses="font-size-big ink-base placeholder-ink-lighter-2 padding-normal padding-left-xbig border border-white-darker-4 border-focus-ink-lighter-1  bg-white-darker-2 bg-focus-white-darker-3"
					inputErrorClasses="font-size-big red-base placeholder-red-lighter-2 padding-normal padding-left-xbig border border-red-lighter-2 border-focus-red-base bg-red-lighter-4 bg-focus-red-lighter-3"
				></b-text-input>
			</div>

			<b-text-input
				:error="errors.password"
				@input="errors.password = ''"
				type="password"
				class="margin-top-big"
				name="password"
				placeholder="Digite sua senha"
				v-model="account.password"
			>
				<div slot="label">
					<p class="font-size-big ink semi-bold margin-top-0 margin-bottom-normal">
						{{ this.edition ? 'Senha' : 'Senha*' }}
					</p>
				</div>
			</b-text-input>

			<p
				@click="generateRandomPassword()"
				style="cursor: pointer;"
				class="semi-bold margin-top-big margin-bottom-0 margin-left-auto align-right font-size-big blue-base"
			>
				Gerar senha automática
			</p>
		</div>

		<div
			slot="footer"
			class="display-flex flex-grow justify-content-between justify-content-md-end"
		>
			<b-button
				class="font-size-big"
				link
				color="gray"
				@clickOrEnter="$refs.settingsAccountModal.hideModal()"
				>Cancelar</b-button
			>
			<b-button
				:loading="loading"
				@clickOrEnter="submitForm()"
				class="margin-left-big font-size-big"
				>Salvar</b-button
			>
		</div>
	</b-modal>
</template>

<script>
import BAvatar from '@bossabox-code/design-system/components/Avatar.vue';
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BTextInput from '@bossabox-code/design-system/components/Input.vue';
import BSelect from '@bossabox-code/design-system/components/Select.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import BIcon from '@bossabox-code/design-system/components/Icon.vue';
import IconRefresh from '@bossabox-code/design-system/icons/icon-refresh.svg';
import IconCloudUpload from '@bossabox-code/design-system/icons/icon-cloud-upload.svg';
import IconError from '@bossabox-code/design-system/icons/icon-error.svg';
import uploadImageMixin from '@/mixins/uploadImage.js';
import VueMask from 'v-mask';
import { mapActions } from 'vuex';
import { getRolesByTypes, getRoleByName, getRolesByValues } from '../utils/roles.js';

import Vue from 'vue';
Vue.use(VueMask);

export default {
	name: 'app-settings-account-modal',
	mixins: [uploadImageMixin],
	components: {
		BAvatar,
		BModal,
		BTextInput,
		BSelect,
		BButton,
		BIcon,
		IconRefresh,
		IconCloudUpload,
		IconError
	},
	data() {
		return {
			edition: false,
			account: {
				_id: '',
				fullName: '',
				avatarColor: '',
				phone: '',
				userType: '',
				roles: [],
				permission: '',
				password: '',
				email: '',
				profilePicture: ''
			},
			loading: false,
			modalTitle: '',
			flagClass: 'flag-icon-br',
			errors: {},
			flagDefaultPosition: '526px',
			flagBigPosition: '752px',
			accountTypes: [],
			permissionLevel: []
		};
	},
	created() {
		this.image = { profilePicture: '' };
		this.uploadingImage = { profilePicture: false };
		this.uploadingImageError = { profilePicture: '' };
	},
	methods: {
		...mapActions('accounts', [
			'createInternalAccount',
			'createSuccessManagerAccount',
			'createRecruiterAccount',
			'updateInternalAccount',
			'updateSuccessManagerAccount',
			'updateRecruiterAccount'
		]),
		showManagerModal(accountObject) {
			this.setFields(accountObject);
			this.accountTypes = ['Produto'];
			this.permissionLevel = getRolesByTypes('M');
			this.$refs.settingsAccountModal.showModal();
		},
		deleteProperty(property) {
			delete this.errors[property];
		},
		showInternalModal(accountObject) {
			this.setFields(accountObject);
			this.accountTypes = ['Admin'];
			this.permissionLevel = getRolesByTypes('A');
			this.$refs.settingsAccountModal.showModal();
		},
		hideModal() {
			this.$refs.settingsAccountModal.hideModal();
		},
		toggleModal() {
			this.$refs.settingsAccountModal.toggleModal();
		},
		generateRandomPassword() {
			this.account.password = Math.random()
				.toString(36)
				.slice(-10);
		},
		permissionChange(e) {
			this.account.roles = [e.value];
		},
		setFields(accountObject) {
			this.resetFields();
			if (accountObject !== undefined) {
				this.account._id = accountObject._id;
				this.edition = true;
				this.account.fullName = accountObject.fullName;
				this.account.phone = accountObject.phone;
				this.account.email = accountObject.email;
				this.account.avatarColor = accountObject.avatarColor;
				this.account.userType = getRolesByValues(
					getRoleByName(accountObject.roles)
				)[0].type.includes('A')
					? 'Admin'
					: 'Produto';
				this.account.roles = [getRoleByName(accountObject.roles)];
				this.account.permission = accountObject.roles;
				this.image.profilePicture = accountObject.profilePicture;
			} else {
				this.edition = false;
			}
		},
		resetFields() {
			this.accountTypes = [];
			(this.account = {
				_id: '',
				fullName: '',
				avatarColor: '',
				phone: '',
				userType: '',
				roles: '',
				permission: '',
				password: '',
				email: '',
				profilePicture: ''
			}),
				(this.image = { profilePicture: '' });
			this.errors = {};
		},
		validateForm() {
			this.errors = {};
			if (!this.account.userType) Vue.set(this.errors, 'userType', 'Selecione um tipo de usuário');
			if (!this.account.permission)
				Vue.set(this.errors, 'permission', 'Selecione um nível de permissionamento');
			if (!this.account.fullName) Vue.set(this.errors, 'fullName', 'Digite um nome completo');

			const regExpEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (!this.account.email) {
				Vue.set(this.errors, 'email', 'Digite um e-mail');
			} else if (!regExpEmail.test(this.account.email)) {
				Vue.set(this.errors, 'email', 'E-mail inválido');
			}

			if (!this.edition && !this.account.password) {
				Vue.set(this.errors, 'password', 'Digite uma senha');
			} else if (this.account.password && this.account.password.length < 8) {
				Vue.set(this.errors, 'password', 'A senha deve ter pelo menos 8 caracteres');
			}
			this.account.phone = this.account.phone ? this.account.phone.replace(/[^0-9]/g, '') : '';
			if (this.account.phone && this.account.phone.length < 11) {
				Vue.set(this.errors, 'phone', 'Digite um número de telefone válido');
			}

			if (Object.keys(this.errors).length == 0) return true;
		},
		async submitForm() {
			if (this.loading) return;
			this.loading = true;

			if (this.validateForm()) {
				try {
					let userResponse = {};
					this.account.profilePicture = this.image.profilePicture;
					if (this.edition) {
						if (this.account.roles == 'SUCCESS_MANAGER')
							userResponse = await this.updateSuccessManagerAccount(this.account);
						else if (this.account.roles == 'RECRUITER')
							userResponse = await this.updateRecruiterAccount(this.account);
						else userResponse = await this.updateInternalAccount(this.account);

						this.$toasts.show({
							message: 'Conta editada com sucesso.',
							timeout: 3000,
							type: 'success'
						});
						this.$emit('onUpdateAccount', userResponse);
					} else {
						if (this.account.roles == 'SUCCESS_MANAGER')
							userResponse = await this.createSuccessManagerAccount(this.account);
						else if (this.account.roles == 'RECRUITER')
							userResponse = await this.createRecruiterAccount(this.account);
						else userResponse = await this.createInternalAccount(this.account);

						this.$emit('onCreateAccount');
						this.$toasts.show({
							message: 'Conta adicionada com sucesso.',
							timeout: 3000,
							type: 'success'
						});
					}
					this.hideModal();
					this.loading = false;
					this.errors = {};
				} catch (err) {
					this.loading = false;
					this.errors = {};
					if (err.code == 'EMAIL_REGISTERED') {
						Vue.set(this.errors, 'email', 'Email já registrado');
					} else
						this.$toasts.show({
							message: err.message,
							timeout: 3000,
							type: 'danger'
						});
				}
			} else {
				this.loading = false;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.3.1/css/flag-icon.min.css');
.flag-icon {
	width: 33px;
	height: 22px;
	position: absolute;
	top: 33px;
	z-index: 2;
	left: 8px;
	@media (max-width: 1200px) {
		width: 36px;
		height: 30px;
		top: 47px;
		left: 15px;
	}
	@media (min-width: 1441px) {
		width: 36px;
		height: 30px;
		top: 37px;
	}
	@media (min-width: 1601px) {
		width: 36px;
		height: 30px;
		top: 46px;
		left: 15px;
	}
}
.container-input-phone {
	position: relative;
}
.logo {
	width: 6.4rem;
	height: 6.4rem;
	border-radius: 5px;
}
</style>
