<template>
	<div class="overflow-hidden">
		<admin-header :user="this.user" tabSelected="challenges" />

		<div class="padding-horizontal-big padding-horizontal-xl-xxbig">
			<div
				class="page-menu margin-horizontal-auto display-flex position-relative flex-row margin-vertical-xbig justify-content-between"
			>
				<div>
					<span
						class="padding-left-lg-0 align-center margin-left-tiny align-left-sm font-size-huge ink-base semi-bold"
						>{{ dataPage.title }}</span
					>
				</div>
				<b-content-switcher
					class="switcher"
					:items="tabNames"
					:active="activeTab"
					@onChange="value => (activeTab = value)"
					horizontal
				/>
				<div class="width-empty-div"></div>
			</div>
			<div
				v-if="activeTab === tabNames[0]"
				:class="[{ 'horizontal-scroll': notEvaluatedInstances > 0 }]"
			>
				<app-challenges-not-evaluated
					ref="challengesNotEvaluated"
					@reload="loadSwitcherNames"
					:key="tab"
					:role="dataPage.roleRequest"
				/>
			</div>
			<div
				v-else-if="activeTab === tabNames[1]"
				:class="[{ 'horizontal-scroll': evaluatedInstances > 0 }]"
			>
				<app-challenges-evaluated ref="challengesEvaluated" :role="dataPage.roleRequest" />
			</div>
		</div>
	</div>
</template>

<script>
import BContentSwitcher from '@bossabox-code/design-system/components/ContentSwitcher.vue';
import AppChallengesNotEvaluated from './ChallengesNotEvaluated.vue';
import AppChallengesEvaluated from './ChallengesEvaluated.vue';
import { mapGetters, mapActions } from 'vuex';
import AdminHeader from '../components/AdminHeader.vue';

export default {
	components: {
		AdminHeader,
		BContentSwitcher,
		AppChallengesNotEvaluated,
		AppChallengesEvaluated
	},
	data() {
		return {
			tabNames: [],
			tab: 0,
			activeTab: '',
			dataPage: {
				title: '',
				role: '',
				roleRequest: ''
			},
			evaluatedInstances: 0,
			notEvaluatedInstances: 0
		};
	},
	computed: {
		...mapGetters('auth', ['user'])
	},
	methods: {
		...mapActions('challenges', ['getChallengesTotals']),
		createDataPage(route = '') {
			if (route === '') this.dataPage.role = this.$route.params.challengeType;
			else this.dataPage.role = route;

			if (this.dataPage.role === 'product-manager') {
				this.dataPage.title = 'Gerente de Produto';
				this.dataPage.roleRequest = 'PRODUCT_MANAGER';
			}
			if (this.dataPage.role === 'product-designer') {
				this.dataPage.title = 'Designer de Produto';
				this.dataPage.roleRequest = 'PRODUCT_DESIGNER';
			}
			if (this.dataPage.role === 'front-end') {
				this.dataPage.title = 'Dev. Front End';
				this.dataPage.roleRequest = 'FRONTEND_DEVELOPER';
			}
			if (this.dataPage.role === 'back-end') {
				this.dataPage.title = 'Dev. Back End';
				this.dataPage.roleRequest = 'BACKEND_DEVELOPER';
			}
			if (this.dataPage.role === 'mobile-developer') {
				this.dataPage.title = 'Mobile Developer';
				this.dataPage.roleRequest = 'MOBILE_DEVELOPER';
			}
			if (this.dataPage.role === 'devops') {
				this.dataPage.title = 'DevOps';
				this.dataPage.roleRequest = 'DEVOPS';
			}
			if (this.dataPage.role === 'quality-assurance') {
				this.dataPage.title = 'Quality Assurance';
				this.dataPage.roleRequest = 'QUALITY_ASSURANCE';
			}

			this.activeTab = this.tabNames[0];
		},
		async loadSwitcherNames() {
			let challengesTotals = await this.getChallengesTotals({ role: this.dataPage.roleRequest });
			this.evaluatedInstances = challengesTotals.totalEvaluatedChallengeInstances;
			this.notEvaluatedInstances = challengesTotals.totalNotEvaluatedChallengeInstances;
			this.tabNames = this.user.permissions.includes('VIEW_TOTAL_CHALLENGES_SENT_BY_PROLANCERS')
				? [
						`Não verificados (${this.notEvaluatedInstances})`,
						`Verificados (${this.evaluatedInstances})`
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: [`Não verificados`, `Verificados`];
			this.activeTab = this.tabNames[0];
			this.tab += 1;
		}
	},
	beforeMount() {
		this.createDataPage();
		this.loadSwitcherNames();
	},
	beforeRouteUpdate(to, from, next) {
		this.createDataPage(to.params.challengeType);
		this.loadSwitcherNames();
		next();
	}
};
</script>

<style scoped lang="scss">
@media (max-width: 1200px) {
	.horizontal-scroll {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		height: 100%;
	}
}

.overflow-hidden {
	overflow-x: hidden;
}

.width-empty-div {
	width: 11%;
}

.page-menu {
	align-items: center;
}

@media (max-width: 990px) {
	.page-menu {
		flex-direction: column;
	}
	.switcher {
		padding-top: 1.6rem;
	}
	.page-menu > div:first-child {
		flex-direction: column;
	}
	.page-menu .switcher {
		width: -webkit-fill-available;
	}
}
</style>
