import VCalendar from 'v-calendar';
import Vue from 'vue';
import { VueHammer } from 'vue2-hammer';

Vue.use(VueHammer);
Vue.use(VCalendar);

const req = require.context('@bossabox-code/design-system/icons/', true, /\.(svg)$/i);
req.keys().map(key => {
	const name = key.substring(key.indexOf('/') + 1, key.lastIndexOf('.'));
	return Vue.component(name, req(key).default);
});