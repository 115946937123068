<template>
	<b-modal ref="confirmDeleteCriteria" tiny>
		<h3 slot="header" class="semi-bold ink-base margin-0">Desativar critério</h3>

		<p class="font-size-big ink-lighter-1 margin-0">
			Ao desativar, você perderá informações. Tem certeza que deseja desativar?
		</p>

		<section slot="footer" class="display-flex flex-wrap justify-content-around justify-content-md-end">
			<b-button class="font-size-big" link color="gray" @clickOrEnter="hideModal()">
				Cancelar
			</b-button>

			<b-button
				:loading="loading"
				class="font-size-big margin-left-big"
				danger
				@clickOrEnter="deleteCriterias()"
			>
				Remover
			</b-button>
		</section>
	</b-modal>
</template>

<script>
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import { mapActions } from 'vuex';

export default {
	name: 'app-confirm-delete-criteria-modal',
	components: {
		BModal,
		BButton,
	},
	data() {
		return {
			customReviewId: '',
			criteriaId: '',
			loading: false,
		};
	},
	methods: {
		...mapActions('customReview', ['deleteCustomReview']),
		showModal(customReviewId, criteriaId) {
			this.customReviewId = customReviewId;
			this.criteriaId = criteriaId;
			this.$refs.confirmDeleteCriteria.showModal();
		},
		hideModal() {
			this.$refs.confirmDeleteCriteria.hideModal();
		},
		async deleteCriterias() {
			try {
				this.loading = true;
				await this.deleteCustomReview({
					customReviewId: this.customReviewId,
					criteriaId: this.criteriaId,
				});
				this.loading = false;
				this.$toasts.show({
					message: 'Critério e evidências desativados com sucesso.',
					timeout: 3000,
					type: 'success',
				});
				this.$trackingService.logAdminEvent('Criteria With Evidences Disabled', {
					stage: this.stage,
					role: this.role,
					criteria: this.criteria,
				});
				this.$emit('onDeleteButtonClicked');
			} catch (err) {
				this.loading = false;
				this.$toasts.show({
					message: 'Erro ao deletar critério e evidências, tente novamente.',
					timeout: 3000,
					type: 'danger',
				});
			}
		},
	},
};
</script>
