<template>
	<section>
		<app-admin-header :user="this.user" tabSelected="recruitment" />

		<div
			class="
				padding-horizontal-big padding-horizontal-xl-xxbig
				padding-vertical-xbig
			"
		>
			<div class="display-flex flex-row">
				<h2>Personalizar avaliação</h2>
			</div>
			<p class="margin-top-tiny ink-lighter-2 font-size-big padding-bottom-tiny">
				Selecione o tipo de entrevista e área de atuação para personalizar critérios e evidências
				das avaliações.
			</p>

			<p class="font-size-big ink semi-bold margin-bottom-normal margin-top-big" slot="label">
				1. Selecione o tipo de avaliação
			</p>

			<b-select
				name="type"
				@input="handleSelection"
				:options="stages"
				v-model="stage"
				class="col-lg-4 col-sm-6"
			/>

			<b-divider class="margin-vertical-big padding-horizontal-xbig" />

			<b-select
				:error="errors.role"
				name="role"
				@input="handleSelection"
				:options="roles"
				v-model="role"
				class="col-lg-4 col-sm-6"
				searchable
			>
				<p>{{ role }}</p>
				<p class="font-size-big ink semi-bold margin-top-big margin-bottom-normal" slot="label">
					2. Selecione a área de atuação
				</p>
			</b-select>

			<div class="border-bottom border-white-darker-4 padding-bottom-big padding-horizontal-xbig" />

			<p
				class="font-size-big ink semi-bold margin-top-0 margin-bottom-tiny margin-top-big"
				slot="label"
			>
				3. Critérios e Evidências
			</p>
			<p class="margin-top-0 ink-lighter-2 font-size-big">
				Abaixo, adicione e edite os critérios a serem avaliados após a entrevista.
			</p>

			<b-card class="border-none">
				<div v-for="(item, index) in customReview.criteriaWithEvidences" :key="index">
					<div class="padding-normal display-flex justify-content-between">
						<p class="margin-tiny font-size-xbig">{{ item.criteria }}</p>
						<b-button
							class="margin-tiny font-size-big display-button"
							link
							@clickOrEnter="showCriteriaWithEvidencesModal(customReview._id, item, true)"
						>
							<b-new-b-icon-edit class="padding-right-tiny" color="blue-base" />
							Editar</b-button
						>
					</div>
					<b-divider />
				</div>
				<b-button
					:disabled="availableCriterias.length === 0"
					link
					class="font-size-big blue-base bold padding-normal display-button"
					@clickOrEnter="showCriteriaWithEvidencesModal"
					><b-new-b-icon-add-circle color="blue-base" class="padding-tiny" />Adicionar
					critérios</b-button
				>
			</b-card>
		</div>
		<app-criteria-with-evidences-modal
			:stage="stage"
			:role="role.value"
			ref="createCriteriaModal"
		/>
	</section>
</template>

<script>
import AppAdminHeader from '../components/AdminHeader.vue';
import AppCriteriaWithEvidencesModal from './CriteriaWithEvidencesModal.vue';
import BSelect from '@bossabox-code/design-system/components/Select.vue';
import BCard from '@bossabox-code/design-system/components/Card.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import BDivider from '@bossabox-code/design-system/components/Divider.vue';
import { BNewBIconAddCircle, BNewBIconEdit } from '@bossabox-code/design-system';
import { getRolesByTypes } from '@/utils/roles.js';
import { mapState, mapActions } from 'vuex';
import criteriasListByStage from '../../utils/criterias.js';
import Vue from 'vue';

export default {
	components: {
		AppAdminHeader,
		BSelect,
		BCard,
		BButton,
		BNewBIconAddCircle,
		BNewBIconEdit,
		AppCriteriaWithEvidencesModal,
		BDivider
	},
	computed: {
		...mapState('auth', ['user']),
		...mapState('customReview', ['customReview']),

		availableCriterias() {
			if (this.stage) {
				const selectedCriterias =
					this.customReview && this.customReview.criteriaWithEvidences
						? this.customReview.criteriaWithEvidences.map(criteria => criteria.criteria)
						: [];

				const criteriasByStage = criteriasListByStage[this.stage];

				const availableCriterias = criteriasByStage.filter(
					criteria => !selectedCriterias.includes(criteria)
				);

				return availableCriterias;
			}

			return [];
		},

		stages() {
			return Object.keys(criteriasListByStage);
		}
	},
	data() {
		return {
			errors: {},
			roles: getRolesByTypes('J'),
			role: { name: '', value: '' },
			stage: ''
		};
	},
	methods: {
		...mapActions('customReview', ['getCustomReview', 'setCustomReview']),
		validateForm() {
			this.errors = {};
			if (!this.role.name) Vue.set(this.errors, 'role', 'Selecione a área de atuação');
			if (Object.keys(this.errors).length == 0) return true;
			return false;
		},
		showCriteriaWithEvidencesModal(customReviewId = '', criteriaWithEvidences = {}, edit = false) {
			if (this.validateForm()) {
				this.$refs.createCriteriaModal.showModal(
					customReviewId,
					criteriaWithEvidences,
					edit,
					this.availableCriterias
				);
			}
		},
		async handleSelection() {
			if (this.role.value !== '' && this.stage !== '') {
				this.errors.role = '';
				await this.getCustomReview({ stage: this.stage, role: this.role.value });
			}
		}
	},
	mounted() {
		this.setCustomReview({});
	}
};
</script>

<style scoped>
.display-button {
	display: flex;
	align-items: center;
}
</style>
