<template>
	<div>
		<b-table
			class="horizontal-scroll"
			v-if="payments && payments.length"
			ref="paymentsTable"
			:items="payments"
			:fields="fields"
			:hasPagination="false"
			cellSize="big"
			tableClasses="margin-horizontal-auto table-width margin-horizontal-xl-tiny margin-bottom-xbig display-flex flex-column"
		>
			<template slot="prolancer" slot-scope="props">
				<div class="display-flex font-size-big flex-column">
					<span>{{ props.item.user.fullName }}</span>
					<span class="ink-lighter-1">{{ getRolesNames(props.item.user.roles) }}</span>
				</div>
			</template>
			<template slot="project" slot-scope="props">
				<span class="font-size-big"> {{ props.item.product.name }} </span>
			</template>
			<template slot="totalPayment" slot-scope="props">
				<span class="font-size-big">
					{{ formatPayment(props.item.biweeklyPayment + props.item.bonus) }}
				</span>
			</template>
			<template slot="_id" slot-scope="props">
				<span class="font-size-big">
					{{ props.item._id }}
				</span>
			</template>
			<template slot="invoiceSentDate" slot-scope="props">
				<span class="font-size-big">
					{{ formatDate(props.item.invoiceSentDate) }}
				</span>
			</template>
			<template slot="releasePayment" slot-scope="props">
				<b-tooltip class="display-inline-block" banner>
					<template slot="banner">
						<object :data="props.item.invoice.filePath" class="preview" type="application/pdf">
							<div>Não existem vizualizadores de pdf instalados</div>
						</object>
					</template>
					<b-button class="font-size-big" link @clickOrEnter="selectPayment(props.item)">
						Liberar pagamento
					</b-button>
				</b-tooltip>
			</template>
		</b-table>
		<div v-else class="display-flex justify-content-center font-size-big">
			<span>
				{{ 'Não há Notas Fiscais a serem liberadas no momento.' }}
			</span>
		</div>

		<app-release-payment-modal
			v-if="!!selectedPayment"
			ref="releasePaymentModal"
			:payment="selectedPayment"
			@releasePaymentButtonClick="handleReleasePaymentButtonClick"
			@rejectInvoiceButtonClick="handleRejectInvoiceButtonClick"
		/>

		<app-release-payment-confirmation-modal
			v-if="!!selectedPayment"
			ref="releasePaymentConfirmationModal"
			:payment="selectedPayment"
			@releasePayment="handleReleasePayment"
		/>

		<app-reject-invoice-confirmation-modal
			v-if="!!selectedPayment"
			ref="rejectInvoiceConfirmationModal"
			:payment="selectedPayment"
			@rejectInvoice="handleRejectInvoice"
		/>
	</div>
</template>

<script>
import AppReleasePaymentModal from './ReleasePaymentModal.vue';
import AppReleasePaymentConfirmationModal from './ReleasePaymentConfirmationModal.vue';
import AppRejectInvoiceConfirmationModal from './RejectInvoiceConfirmationModal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import BTable from '@bossabox-code/design-system/components/Table.vue';
import BTooltip from '@bossabox-code/design-system/components/Tooltip.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { getRolesNames } from '@/utils/roles.js';

export default {
	components: {
		AppReleasePaymentModal,
		AppReleasePaymentConfirmationModal,
		AppRejectInvoiceConfirmationModal,
		BButton,
		BTable,
		BTooltip
	},
	data() {
		return {
			fields: [
				{
					key: 'prolancer',
					value: 'Prolancer'
				},
				{
					key: 'project',
					value: 'Projeto'
				},
				{
					key: 'totalPayment',
					value: 'Valor da NF'
				},
				{
					key: '_id',
					value: 'ID'
				},
				{
					key: 'invoiceSentDate',
					value: 'Data de envio da NF'
				},
				{
					key: 'releasePayment',
					value: ''
				}
			],
			selectedPayment: {}
		};
	},
	computed: {
		...mapGetters('auth', ['user']),
		...mapState('payments', ['payments'])
	},
	methods: {
		...mapActions('payments', ['getPayments', 'releasePayment', 'rejectInvoice']),
		getRolesNames,
		formatDate(createdAt) {
			let date = new Date(createdAt);
			const day = date.toLocaleString('default', { day: 'numeric' });
			const month = date.toLocaleString('default', { month: 'long' });
			const year = date.toLocaleString('default', { year: 'numeric' });
			return day + ' de ' + month + ', ' + year;
		},
		async selectPayment(payment) {
			this.selectedPayment = payment;
			this.$refs.releasePaymentModal.showModal();
		},
		async handleReleasePaymentButtonClick(payment) {
			this.selectedPayment = payment;
			this.$refs.releasePaymentConfirmationModal.showModal();
		},
		async handleRejectInvoiceButtonClick() {
			this.$refs.rejectInvoiceConfirmationModal.showModal();
		},
		async handleReleasePayment() {
			try {
				await this.releasePayment({
					paymentId: this.selectedPayment._id,
					paymentInfo: { retention: this.selectedPayment.retention }
				});

				this.$toasts.show({
					message: 'Pagamento liberado',
					description: 'O pagamento foi enviado para aprovação.',
					timeout: 3000,
					type: 'success'
				});
			} catch (error) {
				this.$toasts.show({
					message: 'Erro ao liberar o pagamento',
					description: error.message,
					timeout: 3000,
					type: 'danger'
				});
			}

			await this.getPayments({ statuses: 'invoice-sent' });
		},
		async handleRejectInvoice(paymentInfo) {
			try {
				await this.rejectInvoice({ paymentId: this.selectedPayment._id, paymentInfo });

				this.$toasts.show({
					message: 'Sucesso!',
					description: 'A nota fiscal do pagamento selecionado foi rejeitada.',
					timeout: 3000,
					type: 'success'
				});
			} catch (error) {
				this.$toasts.show({
					message: 'Erro ao rejeitar nota fiscal',
					description: error.message,
					timeout: 3000,
					type: 'danger'
				});
			}

			await this.getPayments({ statuses: 'invoice-sent' });
		},
		formatPayment(payment) {
			return payment ? payment.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '';
		}
	},
	async mounted() {
		await this.getPayments({ statuses: 'invoice-sent' });
	}
};
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
	.horizontal-scroll {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		height: 100%;
	}
}

.preview {
	width: 100%;
	height: 100%;
}
</style>
