<template>
	<div class="container-box display-flex">
		<div class="logout-box margin-horizontal-auto display-flex flex-column">
			<bbox-icon spin>
				<icon-refresh class="font-size-xhuge" />
			</bbox-icon>
		</div>
	</div>
</template>

<script>
import BboxIcon from '@bossabox-code/design-system/components/Icon.vue';
import IconRefresh from '@bossabox-code/design-system/icons/icon-refresh.svg';
import { mapActions } from 'vuex';

export default {
	name: 'logout',
	components: {
		BboxIcon,
		IconRefresh
	},
	methods: {
		...mapActions('auth', ['logout']),
		redirectAfterLogout() {
			this.$router.push('/');
		}
	},
	async mounted() {
		await this.logout();
		this.redirectAfterLogout();
	}
};
</script>

<style scoped lang="scss">
.container-box {
	background: #ffffff;
	min-height: 100vh;
	align-items: center;
}
.logout-box {
	background: #ffffff;
	width: auto;
	@media (max-width: 767px) {
		width: 90%;
	}
}
</style>
