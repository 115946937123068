<template>
	<b-modal ref="rejectInvoiceConfirmationModal" normal>
		<h3 class="margin-0 semi-bold" slot="header">
			Rejeitar NF de {{ payment.user && payment.user.fullName }}
		</h3>

		<div>
			<b-select
				name="reason"
				:options="reasons"
				v-model="selectedReason"
				:error="errors.reason"
				@change="
					errors.reason = '';
					errors.justification = '';
				"
				searchable
			>
				<p class="font-size-big ink semi-bold margin-top-0 margin-bottom-normal" slot="label">
					Motivo da rejeição*
				</p>
			</b-select>

			<b-textarea
				class="margin-top-normal"
				name="justification"
				v-model.trim="justification"
				maxlength="1000"
				@input="errors.justification = ''"
				:error="errors.justification"
			>
				<div slot="label">
					<p class="font-size-big ink semi-bold margin-top-big">
						Justificativa da rejeição{{ isJustificationRequired ? ' *' : '' }}
					</p>
					<p class="ink-lighter-2 font-size-small margin-top-0">
						Essa justificativa será enviada para a pessoa prolancer, portanto, é necessário um
						cuidado com a comunicação!
					</p>
				</div>
			</b-textarea>
		</div>
		<div
			slot="footer"
			slot-scope=""
			class="display-flex flex-grow justify-content-between justify-content-md-end"
		>
			<b-button class="font-size-big" link color="gray" @clickOrEnter="hideModal()"
				>Cancelar</b-button
			>
			<b-button
				:loading="loading"
				@clickOrEnter="submit()"
				class="margin-left-big font-size-big"
				danger
				>Confirmar</b-button
			>
		</div>
	</b-modal>
</template>

<script>
import { BButton, BModal, BSelect, BTextarea } from '@bossabox-code/design-system';

import Vue from 'vue';

export default {
	name: 'app-reject-invoice-confirmation-modal',
	components: {
		BModal,
		BButton,
		BSelect,
		BTextarea
	},
	props: {
		payment: {
			type: Object,
			required: true
		}
	},
	computed: {
		isJustificationRequired() {
			return this.selectedReason === 'Outros';
		}
	},
	data() {
		return {
			loading: false,
			selectedReason: '',
			reasons: [
				'Arquivo anexado não é uma nota fiscal válida',
				'CNPJ da BossaBox errado na nota',
				'CNPJ do prestador de serviços diferente do cadastrado na plataforma',
				'Data de emissão incorreta ou inválida',
				'Nota fiscal repetida, já anexada em outro pagamento',
				'Numeração da nota fiscal incorreta ou inválida',
				'Tomador de serviço na nota não é a BossaBox',
				'Valor da nota fiscal diferente do valor de pagamento',
				'Outros'
			],
			justification: '',
			errors: {}
		};
	},
	async mounted() {},
	methods: {
		showModal() {
			this.loading = false;
			this.selectedReason = '';
			this.justification = '';
			this.errors = {};
			this.$refs.rejectInvoiceConfirmationModal.showModal();
		},
		hideModal() {
			this.$refs.rejectInvoiceConfirmationModal.hideModal();
		},
		toggleModal() {
			this.$refs.rejectInvoiceConfirmationModal.toggleModal();
		},
		validateErrors() {
			this.errors = {};
			if (!this.selectedReason) Vue.set(this.errors, 'reason', 'Selecione um motivo');
			if (this.selectedReason === 'Outros' && this.justification.length === 0)
				Vue.set(this.errors, 'justification', 'A justificativa deve ser preenchida');

			return Object.keys(this.errors).length === 0;
		},
		async submit() {
			this.loading = true;
			if (this.validateErrors()) {
				this.$emit('rejectInvoice', {
					invoiceRejectedReason: this.selectedReason,
					invoiceRejectedJustification: this.justification
				});
				this.hideModal();
			}

			this.loading = false;
		}
	}
};
</script>
