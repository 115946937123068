import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store.js';
import './registerServiceWorker';
import './dlsConfiguration';
import TrackingService from './services/tracking.service';
import { install as Toast } from '@bossabox-code/design-system/components/Toast.vue';

Vue.config.productionTip = false;

Vue.use(Toast);
Vue.use(TrackingService);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
