import { bossaBoxApi } from './api';

export const customReviewService = {
	async createCustomReview(customReview) {
		const response = (await bossaBoxApi.post('/admin/custom-review', customReview)).data;
		return response;
	},

	async getCustomReview(stage, role) {
		const response = (await bossaBoxApi.get(`/admin/custom-review?stage=${stage}&role=${role}`))
			.data;
		return response;

	},

	async updateCustomReview(customReviewId, criteriaId, updateCustomReview ) {
		const response = (await bossaBoxApi.patch(`/admin/custom-review/${customReviewId}/criteria/${criteriaId}`, updateCustomReview)).data;
		return response;
	},

	async deleteCustomReview(customReviewId, criteriaId) {
		await bossaBoxApi.delete(`/admin/custom-review/${customReviewId}/criteria/${criteriaId}`);
	}
};

let installed = false;

const install = Vue => {
	if (installed) return;

	Object.defineProperties(Vue.prototype, {
		$customReviewService: {
			get() {
				return customReviewService;
			}
		}
	});

	installed = true;
};

export default { install };
