export const TYPES = {
	SET_CUSTOM_REVIEW: 'SET_CUSTOM_REVIEW',
	DELETE_CUSTOM_REVIEW: 'DELETE_CUSTOM_REVIEW'
};

export default {
	[TYPES.SET_CUSTOM_REVIEW](state, customReview) {
		state.customReview = customReview;
	},

	[TYPES.DELETE_CUSTOM_REVIEW](state, criteriaId) {
		state.customReview.criteriaWithEvidences = state.customReview.criteriaWithEvidences.filter(
			criteria => criteria._id != criteriaId
		);
	}
};
