import { authService } from '../services/auth.service';

export default {
	namespaced: true,
	state: {
		userToken: '',
		user: {}
	},
	mutations: {
		SET_USER_TOKEN(state, { token }) {
			state.userToken = token;
		},
		SET_USER(state, { user }) {
			state.user = user;
		}
	},
	actions: {
		async login({ commit }, { email, password }) {
			const { user, token } = await authService.login(email, password);

			commit('SET_USER_TOKEN', { token });
			commit('SET_USER', { user });
			return user;
		},
		async logout({ commit }) {
			if (window.localStorage) window.localStorage.removeItem('userToken');
			commit('SET_USER_TOKEN', '');
			commit('SET_USER', {});
			return true;
		},
		async getUser({ commit }, { token }) {
			if (window.localStorage) window.localStorage.setItem('userToken', token);
			commit('SET_USER_TOKEN', { token });

			try {
				const user = await authService.getUser();
				commit('SET_USER', { user });
				return user;
			} catch (err) {
				window.localStorage.removeItem('userToken');
				throw err;
			}
		},
		async forgotPassword({ commit }, email) {
			commit('SET_USER', {});
			return await authService.forgotPassword(email);
		},
		async changePassword({ commit }, { password, tokenResetPassword }) {
			commit('SET_USER', {});
			return await authService.changePassword(password, tokenResetPassword);
		}
	},
	getters: {
		userToken(state) {
			return state.userToken;
		},
		user(state) {
			return state.user;
		}
	}
};
