<template>
	<b-modal ref="cancelConfirmationModal" normal>
		<h3 class="margin-0 semi-bold" slot="header">Cancelar</h3>
		<span class="font-size-big">
			Tem certeza que deseja cancelar a avaliação? Todas as informações serão perdidas.
		</span>
		<div
			slot="footer"
			class="display-flex flex-grow justify-content-between justify-content-md-end"
		>
			<b-button
				class="font-size-big"
				link
				color="gray"
				@clickOrEnter="$refs.cancelConfirmationModal.hideModal()"
				>Não</b-button
			>
			<b-button danger @clickOrEnter="cancelOption()" class="margin-left-big font-size-big"
				>Sim</b-button
			>
		</div>
	</b-modal>
</template>

<script>
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';

export default {
	name: 'app-cancel-confirmation-modal',
	components: {
		BModal,
		BButton
	},
	methods: {
		showModal() {
			this.$refs.cancelConfirmationModal.showModal();
		},
		hideModal() {
			this.$refs.cancelConfirmationModal.hideModal();
		},
		toggleModal() {
			this.$refs.cancelConfirmationModal.toggleModal();
		},
		cancelOption() {
			this.hideModal();
			this.$emit('cancelOption');
		}
	}
};
</script>
