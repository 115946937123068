<template>
	<div class="overflow-hidden">
		<admin-header :user="this.user" tabSelected="finance" subtabSelected="invoices" />

		<div class="padding-horizontal-big padding-horizontal-xl-xxbig">
			<div
				class="
					page-menu
					display-flex
					position-relative
					flex-row
					margin-vertical-lg-xbig margin-vertical-big
					justify-content-between
				"
			>
				<div>
					<span
						class="
							padding-left-lg-0
							align-center
							margin-left-xl-tiny
							align-left-sm
							font-size-huge
							ink-base
							semi-bold
						"
						>Pagamentos</span
					>
				</div>
				<b-content-switcher
					class="switcher margin-top-big margin-top-lg-0"
					:items="tabNames"
					:active="activeTab"
					@onChange="
						value => {
							activeTab = value;
						}
					"
					horizontal
				/>

				<div
					class="approve-div display-flex"
					v-if="user.permissions.includes('VIEW_ALL_PRODUCTS_PAYMENTS')"
				>
					<b-button
						:class="{ hidden: activeTab === 'Para liberar' }"
						@clickOrEnter="$refs.releasedPaymentsFilter.openFilter()"
						:color="'blue'"
						outline
						class="
							approve
							display-lg-block
							margin-left-md-auto
							font-size-big
							margin-top-big margin-top-lg-0 margin-right-normal
						"
					>
						Filtros
					</b-button>

					<b-button
						:class="{ hidden: activeTab === 'Para liberar' }"
						@clickOrEnter="downloadCSV()"
						class="
							approve
							display-lg-block
							margin-left-md-auto
							font-size-big
							margin-top-big margin-top-lg-0
						"
					>
						Exportar Informações
					</b-button>
				</div>
			</div>

			<payments-to-release v-show="activeTab == 'Para liberar'" />

			<released-payments
				v-show="activeTab === 'Pagamentos liberados'"
				ref="releasedPaymentsFilter"
			/>
		</div>
	</div>
</template>

<script>
import BContentSwitcher from '@bossabox-code/design-system/components/ContentSwitcher.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';

import PaymentsToRelease from '@/views/Finance/PaymentsToRelease.vue';
import ReleasedPayments from './components/ReleasedPayments.vue';
import AdminHeader from '../components/AdminHeader.vue';
import { mapGetters, mapActions } from 'vuex';

import financesService from '@/services/finances.service.js';
import Vue from 'vue';

Vue.use(financesService);

export default {
	components: {
		AdminHeader,
		BContentSwitcher,
		BButton,
		ReleasedPayments,
		PaymentsToRelease
	},
	data() {
		return {
			tabNames: ['Para liberar', 'Pagamentos liberados'],
			activeTab: ''
		};
	},
	methods: {
		...mapActions('payments', ['downloadPayments']),
		async downloadCSV() {
			const filter = this.$refs.releasedPaymentsFilter.getQueryString();
			const csv = await this.downloadPayments(filter);

			const anchor = document.createElement('a');
			anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
			anchor.target = '_blank';
			anchor.download = 'downloads.csv';
			anchor.click();
		}
	},
	computed: {
		...mapGetters('auth', ['user'])
	},
	mounted() {
		this.activeTab = this.tabNames[0];
	}
};
</script>

<style scoped lang="scss">
.overflow-hidden {
	overflow-x: hidden;
}

.width-empty-div {
	width: 11%;
}

.page-menu {
	align-items: center;
}

@media (max-width: 990px) {
	.page-menu {
		flex-direction: column;
	}
	.page-menu > div:first-child {
		flex-direction: column;
	}
	.page-menu .switcher {
		width: fill-available;
	}

	.approve {
		width: fill-available;
	}

	.approve-div {
		width: 100%;
	}
}

.hidden {
	visibility: hidden;
}
</style>
