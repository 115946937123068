<template>
	<div>
		<app-header :tabs="tabs"></app-header>
	</div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
	components: {
		appHeader: Header
	},
	props: {
		tabSelected: {
			type: String
		},
		subtabSelected: {
			type: String
		},
		user: Object
	},
	computed: {
		tabs() {
			let tabs = [];

			if (
				this.user.permissions.includes('VIEW_ALL_ADMINS') &&
				this.user.permissions.includes('VIEW_ALL_USERS')
			) {
				tabs.push({
					title: 'Contas',
					path: `/accounts/internal/`,
					active: this.tabSelected === 'accounts',
					subtabs: [
						{
							title: 'Interno',
							path: `/accounts/internal/`,
							active: this.subtabSelected === 'internal'
						},
						{
							title: 'Gerente de Sucesso',
							path: `/accounts/success-manager/`,
							active: this.subtabSelected === 'successManager'
						},
						{
							title: 'Recrutador(a)',
							path: `/accounts/recruiter/`,
							active: this.subtabSelected === 'recruiter'
						}
					]
				});
			}

			if (this.user.permissions.includes('VIEW_CHALLENGES_SENT_BY_PROLANCERS')) {
				tabs.push({
					title: 'Avaliar Desafios',
					path: `/challenges/review/product-manager`,
					active: this.tabSelected === 'challenges',
					subtabs: [
						{
							title: 'Gerente de Produto',
							path: `/challenges/review/product-manager`,
							active: this.subtabSelected === 'productManager'
						},
						{
							title: 'Designer de produto',
							path: `/challenges/review/product-designer`,
							active: this.subtabSelected === 'productDesigner'
						},
						{
							title: 'Dev. Front End',
							path: `/challenges/review/front-end`,
							active: this.subtabSelected === 'devFrontEnd'
						},
						{
							title: 'Dev. Back End',
							path: `/challenges/review/back-end`,
							active: this.subtabSelected === 'devBackEnd'
						},
						{
							title: 'Dev. Mobile',
							path: `/challenges/review/mobile-developer`,
							active: this.subtabSelected === 'devMobile'
						},
						{
							title: 'DevOps',
							path: `/challenges/review/devops`,
							active: this.subtabSelected === 'devOps'
						},
						{
							title: 'Quality Assurance',
							path: `/challenges/review/quality-assurance`,
							active: this.subtabSelected === 'qualityAssurance'
						}
					]
				});
			}
			if (this.user.permissions.includes('CREATE_CUSTOM_REVIEW')) {
				tabs.push({
					title: 'Recrutamento',
					path: `/recruitment/custom-review`,
					active: this.tabSelected === 'recruitment'
				});
			}

			if (this.user.permissions.includes('VIEW_ALLOCATED_PROLANCERS')) {
				tabs.push({
					title: 'Alocações',
					path: `/prolancers/allocated/`,
					active: this.tabSelected === 'allocatedProlancers'
				});
			}

			if (
				this.user.permissions.includes('VIEW_ACCRUAL_PAYMENTS') ||
				this.user.permissions.includes('VIEW_ALL_PRODUCTS_PAYMENTS')
			) {
				let financePath = this.user.permissions.includes('VIEW_ACCRUAL_PAYMENTS')
					? `/finance/accrual-payments`
					: `/finance/invoices`;
				let financeTab = {
					title: 'Financeiro',
					path: financePath,
					active: this.tabSelected === 'finance',
					subtabs: []
				};

				if (this.user.permissions.includes('VIEW_ACCRUAL_PAYMENTS')) {
					financeTab.subtabs.push({
						title: 'Exportar Competências',
						path: `/finance/accrual-payments`,
						active: this.subtabSelected === 'accrualPayments'
					});
				}

				if (this.user.permissions.includes('VIEW_ALL_PRODUCTS_PAYMENTS')) {
					financeTab.subtabs.push({
						title: 'Pagamentos',
						path: `/finance/invoices`,
						active: this.subtabSelected === 'invoices'
					});
				}

				tabs.push(financeTab);
			}

			return tabs;
		}
	}
};
</script>
