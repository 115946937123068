<template>
	<div class="header bg-ink-base">
		<div
			class="col-offset-lg-1 col-lg-10 col-12 padding-horizontal-tiny padding-horizontal-lg-0 margin-top-0"
		>
			<div class="display-flex">
				<!-- left -->
				<div class="left col-8 col-lg-4 align-left padding-vertical-tiny display-flex">
					<router-link tag="span" to="/" style="cursor: pointer">
						<bbox-icon class="icon margin-right-tiny"
							><icon-bossabox class="logo white-base"></icon-bossabox
						></bbox-icon>
					</router-link>
				</div>
				<!-- middle -->
				<div class="medium col-4 align-center display-none display-lg-flex justify-content-center">
					<ul v-if="tabs" class="tabs display-flex justify-content-center padding-0 margin-0">
						<template v-for="tab in tabs">
							<li v-if="tab.external" class="margin-right-big" :key="tab.path">
								<a
									:href="tab.path"
									class="tab font-size-xbig white-darker-4 hover-white-base focus-white-base active-white-base semi-bold"
									target="_blank"
									style="text-decoration: none"
									>{{ tab.title }}</a
								>
							</li>
							<router-link
								v-else
								tag="li"
								:key="tab.path"
								:class="{ active: $route.path === tab.path || tab.active }"
								:to="tab.path"
								class="tab font-size-xbig white-darker-4 hover-white-base focus-white-base active-white-base semi-bold margin-right-big"
								>{{ tab.title }}</router-link
							>
						</template>
					</ul>
				</div>
				<!-- right -->
				<div class="right col-4 display-flex justify-content-end padding-vertical-tiny">
					<div
						class="display-none display-lg-flex position-relative"
						v-focus-outside="closeUserMenu"
						v-click-outside="closeUserMenu"
					>
						<bbox-button class="header-menu-button" link @clickOrEnter="toogleUserMenu">
							<bbox-avatar
								tiny
								:name="dataUser.fullName"
								:color="dataUser.avatarColor"
								:avatarUrl="dataUser.profilePicture"
							/>
							<bbox-icon class="icon">
								<icon-chevron-down
									v-if="!showUserMenu"
									class="chevron chevron-down ink-lighter-1"
								></icon-chevron-down>
								<icon-chevron-up v-else class="chevron chevron-up ink-lighter-1"></icon-chevron-up>
							</bbox-icon>
						</bbox-button>
						<bbox-card v-if="showUserMenu" class="display-flex user-menu bg-white-base flex-wrap">
							<div
								class="display-flex align-content-center align-items-center w100 padding-normal padding-lg-big"
							>
								<bbox-avatar
									big
									:name="dataUser.fullName"
									:color="dataUser.avatarColor"
									:avatarUrl="dataUser.profilePicture"
								/>
								<div class="margin-left-normal" style="flex-grow: 0;">
									<div>
										<div class="ink-base font-size-xbig margin-top-tiny">{{ userName }}</div>
										<div class="ink-lighter-1 font-size-big">
											{{ getRolesNames(dataUser.roles[0]) }}
										</div>
									</div>
								</div>
							</div>
							<div
								class="display-flex flex-wrap padding-normal padding-lg-big w100 border-top border-white-darker-4"
							>
								<bbox-button
									link
									color="grey"
									class="font-size-big w100 display-flex header-menu-button"
									@clickOrEnter="submitLogout"
								>
									<bbox-icon><icon-block /></bbox-icon>
									<span class="margin-left-normal">Sair</span>
								</bbox-button>
							</div>
						</bbox-card>
					</div>
					<div class="display-flex display-lg-none position-relative">
						<bbox-button link class="" @clickOrEnter="toogleMobileMenu">
							<bbox-icon><icon-menu class="ink-lighter-1 font-size-huge"></icon-menu></bbox-icon>
						</bbox-button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="getActiveSubtabs">
			<div class="border-top border-white-darker-3"></div>
			<div
				class="col-offset-lg-1 col-lg-10 col-12 padding-horizontal-tiny padding-horizontal-lg-0 margin-top-0"
			>
				<div class="display-flex">
					<!-- left -->
					<div class="left col-12 align-left display-none display-lg-flex justify-content-start">
						<ul class="tabs subtabs display-flex justify-content-center padding-0 margin-0">
							<template v-for="tab in getActiveSubtabs">
								<li v-if="tab.external" class="margin-right-big" :key="tab.path">
									<a
										:href="tab.path"
										class="tab padding-vertical-normal font-size-big white-darker-4 hover-white-base focus-white-base active-white-base semi-bold"
										target="_blank"
										style="text-decoration: none"
										>{{ tab.title }}</a
									>
								</li>
								<router-link
									v-else
									tag="li"
									:key="tab.path"
									:class="{ active: $route.path === tab.path || tab.active }"
									:to="tab.path"
									class="tab padding-vertical-normal font-size-big white-darker-4 hover-white-base focus-white-base active-white-base semi-bold margin-right-big"
									>{{ tab.title }}</router-link
								>
							</template>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div v-if="showMobileMenu" class="flex mobile-menu w100 bg-white-base">
			<div
				class="display-flex flex-wrap w100 padding-normal padding-lg-big border-white-darker-4 border-bottom"
			>
				<bbox-avatar
					big
					:name="dataUser.fullName"
					:color="dataUser.avatarColor"
					:avatarUrl="dataUser.profilePicture"
				/>
				<div class="margin-left-normal">
					<div class="ink-base font-size-xbig margin-top-tiny">{{ userName }}</div>
					<div class="ink-lighter-1 font-size-big">{{ getRolesNames(dataUser.roles[0]) }}</div>
				</div>
			</div>
			<div
				class="display-flex flex-wrap padding-normal padding-lg-big border-white-darker-4 border-bottom"
			>
				<bbox-button
					link
					color="grey"
					class="font-size-big w100 display-flex margin-vertical-normal header-menu-button"
					@clickOrEnter="submitLogout"
				>
					<bbox-icon><icon-block /></bbox-icon>
					<span class="margin-left-normal">Sair</span>
				</bbox-button>
			</div>
			<div
				class="display-flex flex-wrap padding-normal padding-lg-big border-white-darker-4 border-bottom"
				v-if="tabs.length > 0"
			>
				<ul
					v-if="tabs"
					class="tabs display-flex justify-content-center padding-0 margin-0 w100 flex-wrap"
				>
					<router-link
						tag="li"
						v-for="tab in tabs"
						:key="tab.path"
						:to="tab.path"
						:class="{ active: tab.active }"
						class="tab font-size-xbig w100 white-darker-4 align-center margin-vertical-normal ink-lighter-1 hover-ink-base focus-ink-base active-ink-base semi-bold padding-left-normal"
					>
						{{ tab.title }}
					</router-link>
				</ul>
			</div>
			<div
				class="display-flex flex-wrap padding-normal padding-lg-big"
				v-if="getActiveSubtabs.length > 0"
			>
				<ul
					class="tabs subtabs display-flex justify-content-center padding-0 margin-0 w100 flex-wrap"
				>
					<router-link
						tag="li"
						v-for="tab in getActiveSubtabs"
						:key="tab.path"
						:class="{ active: $route.path === tab.path || tab.active }"
						:to="tab.path"
						class="tab margin-vertical-tiny w100 white-darker-4 font-size-big ink-lighter-1 hover-ink-base focus-ink-base active-ink-base semi-bold padding-left-normal"
					>
						{{ tab.title }}
					</router-link>
				</ul>
			</div>
		</div>
		<!--<app-confirm-logout-modal ref="confirmLogoutModal" />-->
	</div>
</template>

<script>
import BboxAvatar from '@bossabox-code/design-system/components/Avatar.vue';
import BboxButton from '@bossabox-code/design-system/components/Button.vue';
import BboxCard from '@bossabox-code/design-system/components/Card.vue';
import BboxIcon from '@bossabox-code/design-system/components/Icon.vue';
import IconBossabox from '@bossabox-code/design-system/icons/icon-bossabox.svg';
import IconChevronDown from '@bossabox-code/design-system/icons/icon-chevron-down.svg';
import IconMenu from '@bossabox-code/design-system/icons/icon-menu.svg';

import { mapState, mapActions } from 'vuex';
import { getRolesNames } from '../utils/roles';
export default {
	props: {
		tab: {
			type: String,
			default: ''
		},
		tabs: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	directives: {
		'click-outside': {
			bind: function(el, binding, vnode) {
				el.clickOutsideEvent = function(event) {
					// here I check that click was outside the el and his childrens
					if (!(el == event.target || el.contains(event.target))) {
						// and if it did, call method provided in attribute value
						vnode.context[binding.expression](event);
					}
				};
				document.body.addEventListener('click', el.clickOutsideEvent);
			},
			unbind: function(el) {
				document.body.removeEventListener('click', el.clickOutsideEvent);
			}
		},
		'focus-outside': {
			bind: function(el, binding, vnode) {
				el.focusOutsideEvent = function(event) {
					// here I check that focus was outside the el and his childrens
					if (!(el == event.target || el.contains(event.target))) {
						// and if it did, call method provided in attribute value
						vnode.context[binding.expression](event);
					}
				};
				document.addEventListener('focusin', el.focusOutsideEvent);
			},
			unbind: function(el) {
				document.removeEventListener('focusin', el.focusOutsideEvent);
			}
		}
	},
	data() {
		return {
			dataUser: {},
			showUserMenu: false,
			showMobileMenu: false
		};
	},
	components: {
		BboxAvatar,
		BboxButton,
		BboxCard,
		BboxIcon,
		IconBossabox,
		IconChevronDown,
		IconMenu
	},
	computed: {
		...mapState('auth', ['user']),
		getActiveSubtabs() {
			const subtabs = this.tabs.reduce(
				(acc, current) => (acc ? acc : current.active && current.subtabs ? current.subtabs : false),
				false
			);
			return subtabs;
		},
		userName() {
			const nameArray =
				this.dataUser.fullName &&
				this.dataUser.fullName !== '' &&
				this.dataUser.fullName !== undefined
					? this.dataUser.fullName.split(' ')
					: '?';
			const firstName = nameArray[0].charAt(0).toUpperCase() + nameArray[0].slice(1);
			const lastName =
				nameArray.length > 1
					? nameArray[nameArray.length - 1].charAt(0).toUpperCase() +
					  nameArray[nameArray.length - 1].slice(1) // eslint-disable-line no-mixed-spaces-and-tabs
					: '';
			return firstName + ' ' + lastName;
		}
	},
	methods: {
		...mapActions('auth', ['logout']),
		getRolesNames,
		toogleUserMenu() {
			this.showUserMenu = !this.showUserMenu;
		},
		toogleMobileMenu() {
			this.showMobileMenu = !this.showMobileMenu;
		},
		submitLogout() {
			this.logout();
			this.$router.push('/');
		},
		closeUserMenu() {
			if (this.showUserMenu != false) {
				this.showUserMenu = false;
			}
		}
	},
	async mounted() {
		this.dataUser = await this.user;
	}
};
</script>

<style lang="scss" scoped>
.pointer {
	cursor: pointer;
}
.header {
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14);
}
.icon {
	transform: translate(0);
}
.logo {
	font-size: 4.8rem;
}
.chevron {
	font-size: 2.8rem;
}
.left,
.right {
	align-items: center;
}
.tab {
	white-space: nowrap;
}
ul {
	list-style: none;
}
ul.tabs {
	top: 1px;
	align-items: center;
	position: relative;
	z-index: 3;
}
ul.tabs li.active,
ul.tabs li:hover {
	color: #fff;
	border-bottom: 3px solid #fff;
	font-weight: 500;
	@media (max-width: 991px) {
		border-bottom: 0px;
		border-left: 2px solid #808080;
		color: #808080;
		line-height: 1.2;
	}
}
ul.tabs li {
	cursor: pointer;
	position: relative;
	border-bottom: 2px solid transparent;
	height: 100%;
	display: flex;
	align-items: center;
	@media (max-width: 991px) {
		line-height: 1.2;
		height: auto;
		border-bottom: 0px;
		border-left: 2px solid transparent;
	}
}
ul.subtabs li {
	line-height: 2.6rem;
	@media (max-width: 991px) {
		line-height: 1.2;
	}
}
.header-menu-button {
	padding: 0px;
	display: flex !important;
	cursor: pointer;
	align-items: center;
}
.header-menu-button-badge {
	border-radius: 0.75rem;
	// width: 1.5rem;
	height: 1.5rem;
	padding: 0 0.4rem 0 0.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -0.2rem;
	right: -0.4rem;
}
.user-menu {
	position: absolute;
	top: 5.5rem;
	right: 0px;
	box-shadow: 0px 9px 12px #00000024;
	width: 48rem;
	z-index: 999;
}
.notifications-menu {
	position: absolute;
	top: 5rem;
	right: 0px;
	box-shadow: 0px 9px 12px #00000024;
	width: 64rem;
	max-height: 80vh;
	overflow: auto;
	z-index: 999;
}
.notifications-item {
}
.notifications-item:hover {
	background-color: #f5f7ff;
	// background-color: #F8F8F9
}
.mobile-menu {
	// box-shadow: 0px 9px 12px #00000024;
	box-shadow: 0 9px 12px -10px #00000024;
	position: absolute;
	z-index: 2;
	@media (min-width: 998px) {
		display: none;
	}
}
</style>
