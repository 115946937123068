import { accountsService } from '../services/accounts.service';

export default {
	namespaced: true,
	state: {
		internalAccount: {},
		successManagerAccount: {},
		recruiterAccount: {},
		internalAccounts: [],
		successManagerAccounts: [],
		recruiterAccounts: []
	},
	mutations: {
		SET_INTERNAL_ACCOUNTS(state, { internalAccounts }) {
			state.internalAccounts = internalAccounts;
		},
		SET_SUCCESS_MANAGER_ACCOUNTS(state, { successManagerAccounts }) {
			state.successManagerAccounts = successManagerAccounts;
		},
		SET_RECRUITER_ACCOUNTS(state, { recruiterAccounts }) {
			state.recruiterAccounts = recruiterAccounts;
		},
		SET_ALLOCATED_PROLANCERS(state, { allocatedProlancers }) {
			state.allocatedProlancers = allocatedProlancers;
		},
		SET_INTERNAL_ACCOUNT(state, { internalAccount }) {
			state.internalAccount = internalAccount;
		},
		SET_SUCCESS_MANAGER_ACCOUNT(state, { successManagerAccount }) {
			state.successManagerAccount = successManagerAccount;
		},
		SET_RECRUITER_ACCOUNT(state, { recruiterAccount }) {
			state.recruiterAccount = recruiterAccount;
		}
	},
	actions: {
		async getInternalAccounts({ commit }) {
			const internalAccounts = await accountsService.getInternalAccounts();
			commit('SET_INTERNAL_ACCOUNTS', { internalAccounts });
			return internalAccounts;
		},
		async getSuccessManagerAccounts({ commit }) {
			const successManagerAccounts = await accountsService.getSuccessManagerAccounts();
			commit('SET_SUCCESS_MANAGER_ACCOUNTS', { successManagerAccounts });
			return successManagerAccounts;
		},
		async getRecruiterAccounts({ commit }) {
			const recruiterAccounts = await accountsService.getRecruiterAccounts();
			commit('SET_RECRUITER_ACCOUNTS', { recruiterAccounts });
			return recruiterAccounts;
		},
		async getAllocatedProlancers({ commit }) {
			const allocatedProlancers = await accountsService.getAllocatedProlancers();
			commit('SET_ALLOCATED_PROLANCERS', { allocatedProlancers });
			return allocatedProlancers;
		},
		async createInternalAccount(
			{ commit, state },
			{ fullName, email, roles, password, userType, phone, profilePicture }
		) {
			const internalAccount = await accountsService.createInternalAccount({
				fullName,
				email,
				roles,
				password,
				userType,
				phone,
				profilePicture
			});
			commit('SET_INTERNAL_ACCOUNTS', [...state.internalAccounts, internalAccount]);
			return internalAccount;
		},
		async createSuccessManagerAccount(
			{ commit, state },
			{ fullName, email, roles, password, userType, phone, profilePicture }
		) {
			const successManagerAccount = await accountsService.createManagerAccount({
				fullName,
				email,
				roles,
				password,
				userType,
				phone,
				profilePicture
			});
			commit('SET_SUCCESS_MANAGER_ACCOUNTS', [
				...state.successManagerAccounts,
				successManagerAccount
			]);
			return successManagerAccount;
		},
		async createRecruiterAccount(
			{ commit, state },
			{ fullName, email, roles, password, userType, phone, profilePicture }
		) {
			const recruiterAccount = await accountsService.createManagerAccount({
				fullName,
				email,
				roles,
				password,
				userType,
				phone,
				profilePicture
			});
			commit('SET_RECRUITER_ACCOUNTS', [...state.recruiterAccounts, recruiterAccount]);
			return recruiterAccount;
		},
		async updateInternalAccount({ commit }, account) {
			const { internalAccount } = await accountsService.updateInternalAccount(account, account._id);
			commit('SET_INTERNAL_ACCOUNT', { internalAccount });

			return internalAccount;
		},
		async updateSuccessManagerAccount({ commit }, account) {
			const { manager: successManagerAccount } = await accountsService.updateManagerAccount(
				account,
				account._id
			);
			commit('SET_SUCCESS_MANAGER_ACCOUNT', { successManagerAccount });

			return successManagerAccount;
		},
		async updateRecruiterAccount({ commit }, account) {
			const { manager: recruiterAccount } = await accountsService.updateManagerAccount(
				account,
				account._id
			);
			commit('SET_RECRUITER_ACCOUNT', { recruiterAccount });

			return recruiterAccount;
		},
		// eslint-disable-next-line no-empty-pattern
		async deleteInternalAccount({}, { _id }) {
			await accountsService.deleteInternalAccount(_id);
		},
		// eslint-disable-next-line no-empty-pattern
		async deleteManagerAccount({}, { _id }) {
			await accountsService.deleteManagerAccount(_id);
		}
	},
	getters: {
		internalAccounts(state) {
			return state.internalAccounts;
		},
		successManagerAccounts(state) {
			return state.successManagerAccounts;
		},
		recruiterAccounts(state) {
			return state.recruiterAccounts;
		},
		internalAccount(state) {
			return state.internalAccount;
		},
		successManagerAccount(state) {
			return state.successManagerAccount;
		},
		recruiterAccount(state) {
			return state.recruiterAccount;
		}
	}
};
