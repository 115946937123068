<template>
	<div id="app" class="bg-white-darker-1">
		<div id="nav"></div>
		<router-view />
		<app-toaster />
	</div>
</template>

<script>
import AppToaster from '@bossabox-code/design-system/components/Toast.vue';
import { mapState } from 'vuex';
export default {
	components: {
		AppToaster
	},
	computed: {
		...mapState('auth', ['user'])
	},
	async created() {
		if (this.user && this.user._id) {
			this.$trackingService.initialize(this.user);
		} else {
			this.$trackingService.initialize(null, true);
		}
	}
};
</script>

<style lang="scss">
@import url('https://rsms.me/inter/inter.css');
@import '@bossabox-code/design-system/css/styling.scss';
@import '@bossabox-code/design-system/css/elevation.scss';
@import '@bossabox-code/design-system/css/grid.scss';
@import '@bossabox-code/design-system/css/position.scss';
@import '@bossabox-code/design-system/css/spacing.scss';
@import '@bossabox-code/design-system/css/typography.scss';
body,
html {
	padding: 0;
	margin: 0;
}
* {
	box-sizing: border-box;
}
#app {
	min-height: 100vh;
}
</style>
