<template>
	<b-modal ref="releasePaymentModal" huge noPadding>
		<h3 class="semi-bold margin-big" slot="header">
			Pagamento de {{ payment.user && payment.user.fullName }}
		</h3>

		<b-content-switcher
			class="margin-horizontal-normal"
			:items="tabNames"
			:active="activeTab"
			@onChange="
				value => {
					activeTab = value;
				}
			"
			horizontal
		/>

		<div class="margin-big" v-if="activeTab === 'Informações'">
			<div class="display-flex flex-column margin-top-normal">
				<span class="font-size-big sb">ID do pagamento</span>
				<span class="ink-lighter-1 font-size-big">{{ payment._id }}</span>
			</div>
			<div class="display-flex flex-column margin-top-normal">
				<span class="font-size-big sb">Prolancer</span>
				<span class="ink-lighter-1 font-size-big">{{ payment.user && payment.user.fullName }}</span>
			</div>
			<div class="display-flex flex-column margin-top-normal">
				<span class="font-size-big sb">E-mail</span>
				<span class="ink-lighter-1 font-size-big">{{ payment.user && payment.user.email }}</span>
			</div>
			<div class="display-flex flex-column margin-top-normal">
				<span class="font-size-big sb">CNPJ</span>
				<span class="ink-lighter-1 font-size-big">{{
					payment.user && payment.user.companyInfo.cnpj
				}}</span>
			</div>
			<div class="display-flex flex-column margin-top-normal">
				<span class="font-size-big sb">Projeto</span>
				<span class="ink-lighter-1 font-size-big">{{
					payment.product && payment.product.name
				}}</span>
			</div>
			<div class="display-flex flex-column margin-top-normal">
				<span class="font-size-big sb">Valor da nota fiscal</span>
				<span class="ink-lighter-1 font-size-big">{{
					payment.invoice && payment.invoice.value | formatMoneyBRL
				}}</span>
			</div>
			<div class="display-flex flex-column margin-top-normal">
				<span class="font-size-big sb">Número da nota fiscal</span>
				<span class="ink-lighter-1 font-size-big">{{
					payment.invoice && payment.invoice.number
				}}</span>
			</div>
			<div class="display-flex flex-column margin-top-normal">
				<span class="font-size-big sb">Data de emissão da nota fiscal</span>
				<span class="ink-lighter-1 font-size-big">{{
					payment.invoice && payment.invoice.emissionDate | date
				}}</span>
			</div>
			<div class="display-flex flex-column margin-top-normal">
				<span class="font-size-big sb">Arquivo anexado</span>
				<a
					:href="payment.invoice && payment.invoice.filePath"
					target="blank"
					class="ink-lighter-1 font-size-big"
					>{{ payment.invoice && payment.invoice.filePath | formatFilePath }}</a
				>
			</div>
		</div>

		<div class="margin-horizontal-big" v-else>
			<div class="row cols-gutter-tiny">
				<div class="col-6">
					<b-text-input
						class="margin-top-big"
						name="retention"
						v-model="computedRetention"
						:error="errors.retention"
					>
						<div slot="label">
							<p class="font-size-big ink semi-bold margin-bottom-normal">
								Valor da retenção
							</p>
						</div>
					</b-text-input>
				</div>

				<div class="col-6">
					<b-text-input
						class="margin-top-big"
						name="invoiceValue"
						:value="formatNumberToCurrency(payment.invoice.value)"
						disabled
					>
						<div slot="label">
							<p class="font-size-big ink semi-bold margin-bottom-normal">
								Valor da nota fiscal
							</p>
						</div>
					</b-text-input>
				</div>
			</div>

			<object
				class="margin-vertical-big w100"
				:data="payment.invoice.filePath"
				type="application/pdf"
				style="height: 50vh"
			>
				<div>No online PDF viewer installed</div>
			</object>
		</div>

		<div slot="footer" class="display-flex justify-content-between align-items-center">
			<div class="">
				<b-button
					class="font-size-big"
					link
					danger
					@clickOrEnter="handleRejectInvoiceButtonClick()"
				>
					Rejeitar nota fiscal
				</b-button>
			</div>
			<div>
				<b-button class="font-size-big float-right" link color="gray" @clickOrEnter="cancel()"
					>Cancelar</b-button
				>
				<b-button
					:loading="loading"
					@clickOrEnter="handleReleasePaymentButtonClick()"
					class="margin-left-big font-size-big float-right"
					:disabled="!!errors.retention"
					>Liberar Pagamento</b-button
				>
			</div>
		</div>
	</b-modal>
</template>

<script>
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import BContentSwitcher from '@bossabox-code/design-system/components/ContentSwitcher.vue';
import BTextInput from '@bossabox-code/design-system/components/Input.vue';

import Vue from 'vue';

Vue.filter('formatMoneyBRL', function(value) {
	if (!value) return '';
	const formated = value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
	return formated;
});

Vue.filter('formatFilePath', function(value) {
	if (!value) return '';
	const filePath = value.split('/');
	return filePath[filePath.length - 1];
});

export default {
	name: 'app-release-payment-modal',
	components: {
		BModal,
		BButton,
		BContentSwitcher,
		BTextInput
	},
	data() {
		return {
			activeTab: 'Informações',
			tabNames: ['Informações', 'Nota Fiscal'],
			retention: 0,
			errors: {},
			loading: false
		};
	},
	props: {
		payment: {
			type: Object,
			required: true
		}
	},
	methods: {
		async showModal() {
			this.activeTab = 'Informações';
			this.retention = 0;
			this.errors = {};
			this.$refs.releasePaymentModal.showModal();
		},
		async cancel() {
			this.$refs.releasePaymentModal.hideModal();
		},
		async handleReleasePaymentButtonClick() {
			this.$emit('releasePaymentButtonClick', { ...this.payment, retention: this.retention });

			this.cancel();
		},
		async handleRejectInvoiceButtonClick() {
			this.$emit('rejectInvoiceButtonClick');

			this.cancel();
		},
		formatCurrencyToNumber(value) {
			return parseFloat(
				value
					.toString()
					.replace(/[R$.]*/g, '')
					.replace('.', '')
					.replace(',', '.')
					.trim()
			);
		},
		formatNumberToCurrency(value) {
			return new Intl.NumberFormat('pt', {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 2
			}).format(value);
		}
	},
	computed: {
		computedRetention: {
			get: function() {
				if (!this.retention) {
					Vue.set(this, 'retention', 0);
				}

				return this.formatNumberToCurrency(this.retention);
			},
			set: function(newValue) {
				Vue.set(
					this,
					'retention',
					this.formatCurrencyToNumber(newValue.replace(/[^\d]/g, '')) / 100 // retira os caracteres não numéricos e seta o valor com 2 casas decimais
				);

				if (this.retention >= this.payment.biweeklyPayment + this.payment.bonus) {
					Vue.set(
						this.errors,
						'retention',
						'O valor da retenção não pode ser maior que o valor da nota fiscal.'
					);
				} else {
					Vue.set(this.errors, 'retention', '');
				}
			}
		}
	}
};
</script>

<style scoped></style>
