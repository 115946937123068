import { bossaBoxApi } from './api';

export const financesService = {
	async getAccrualPayments(from, to, completedOnly = true) {
		const paymentsAsCsv = (
			await bossaBoxApi.get(
				`/admin/payments/accrual?from=${from}&to=${to}&completedOnly=${completedOnly}`
			)
		).data;
		return paymentsAsCsv;
	},
	async getPayments(statuses) {
		const payments = (await bossaBoxApi.get(`/admin/payments/?status=${statuses}`)).data;
		return payments;
	},
	async downloadPayments(filter) {
		const payments = (await bossaBoxApi.get(`/admin/download-payments?${filter}`)).data;
		return payments;
	},
	async getFilteredPayments(filter) {
		const payments = (await bossaBoxApi.get(`/admin/payments?${filter}`)).data;
		return payments;
	},
	async releasePayment(paymentId, paymentInfo) {
		await bossaBoxApi.patch(`/admin/payments/${paymentId}/approve-invoice`, paymentInfo);
	},
	async rejectInvoice(paymentId, paymentInfo) {
		await bossaBoxApi.patch(`/admin/payments/${paymentId}/reject-invoice`, paymentInfo);
	}
};

let installed = false;

const install = Vue => {
	if (installed) return;

	Object.defineProperties(Vue.prototype, {
		$financesService: {
			get() {
				return financesService;
			}
		}
	});

	installed = true;
};

export default { install };
