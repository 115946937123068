<template>
	<b-modal ref="CriteriaWithEvidencesModal">
		<h3 class="margin-0" slot="header">Critérios e Evidências</h3>

		<section>
			<p class="font-size-big ink semi-bold margin-top-0 margin-bottom-normal" slot="label">
				Selecione o critério
			</p>
			<b-select
				:error="criteriaError"
				@input="criteriaError = ''"
				:disabled="edit"
				name="criteria"
				:options="criterias"
				v-model="criteria"
				class="col-lg-6 col-sm-4 padding-bottom-tiny"
			/>

			<div v-if="criteria.length > 0">
				<p class="font-size-big ink semi-bold margin-top-normal margin-bottom-0" slot="label">
					Evidências
				</p>
				<p class="margin-top-tiny ink-lighter-1 font-size-normal">
					Adicione evidências que vão validar a existência do critério no candidato entrevistado.
				</p>

				<div v-for="(item, index) in evidences" :key="index">
					<p class="semi-bold">{{ index + 1 }}#</p>
					<div class="font-size-big display-flex align-items-center">
						<b-input
							:error="evidencesError[index]"
							@input="evidencesError.splice(index, 1)"
							:disabled="!!item._id"
							name="evidence"
							placeholder="Digite a evidência"
							v-model="evidences[index].evidence"
							style="width: 95%"
							maxlength="164"
							:counter="!item._id"
						/>

						<b-button
							v-if="evidences.length > 1"
							@clickOrEnter="handleDeleteEvidence(item, index)"
							link
							><b-icon><icon-delete style="width: 3.2rem; color: #cf3430"/></b-icon
						></b-button>
					</div>
				</div>

				<b-button
					v-if="evidences.length < 7"
					:disabled="!(evidences[evidences.length - 1].evidence.length > 0)"
					link
					class="font-size-big blue-base bold padding-left-0 margin-top-normal"
					@clickOrEnter="handleAddNewClick"
					><b-icon class="padding-right-tiny"><icon-add-circle color="blue-base"/></b-icon>Adicionar
					evidências</b-button
				>
			</div>
		</section>

		<div
			:class="{ 'justify-content-end': !edit, 'justify-content-between': edit }"
			slot="footer"
			class="display-flex justify-content-end"
		>
			<b-button
				v-if="edit"
				class="font-size-big padding-0"
				link
				danger
				@clickOrEnter="$refs.confirmDeleteCriteria.showModal(customReviewId, criteriaId)"
				>Desativar critério</b-button
			>
			<div>
				<b-button
					class="font-size-big display-flex justify-content-end"
					link
					color="gray"
					@clickOrEnter="$refs.confirmCancelCriteria.showModal()"
					>Cancelar</b-button
				>
				<b-button
					:loading="loading"
					@clickOrEnter="submitCriterias()"
					class="margin-left-normal font-size-big"
					>{{ edit ? 'Salvar' : 'Adicionar' }}</b-button
				>
			</div>
		</div>
		<app-confirm-delete-criteria-modal
			ref="confirmDeleteCriteria"
			@onDeleteButtonClicked="hideModal()"
		/>
		<app-confirm-cancel-criteria-modal
			ref="confirmCancelCriteria"
			@onCancelButtonClicked="hideModal()"
		/>
	</b-modal>
</template>

<script>
import AppConfirmDeleteCriteriaModal from './ConfirmDeleteCriteriaModal.vue';
import AppConfirmCancelCriteriaModal from './ConfirmCancelCriteriaModal.vue';
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import BSelect from '@bossabox-code/design-system/components/Select.vue';
import BInput from '@bossabox-code/design-system/components/Input.vue';
import BIcon from '@bossabox-code/design-system/components/Icon.vue';
import { mapActions } from 'vuex';
import Vue from 'vue';

export default {
	name: 'app-create-criteria-modal',
	props: {
		stage: {
			type: String,
			required: true
		},
		role: {
			type: String,
			required: true
		}
	},
	components: {
		BModal,
		BButton,
		BSelect,
		BInput,
		BIcon,
		AppConfirmDeleteCriteriaModal,
		AppConfirmCancelCriteriaModal
	},
	data() {
		return {
			criteriaError: '',
			evidencesError: [],
			customReviewId: '',
			criteria: '',
			criterias: [],
			criteriaId: '',
			evidences: [''],
			deletedEvidences: [],
			loading: false,
			edit: false,
		};
	},

	methods: {
		...mapActions('customReview', ['createCustomReview', 'updateCustomReview']),
		showModal(
			customReviewId = '',
			criteriaWithEvidences = {},
			edit = false,
			availableCriterias = []
		) {
			this.edit = edit;

			if (edit) {
				this.customReviewId = customReviewId;
				this.criteriaId = criteriaWithEvidences._id;
				this.criteria = criteriaWithEvidences.criteria;
				this.evidences = criteriaWithEvidences.evidences;
				this.criteriaError = '';
				this.evidencesError = [];
			} else {
				this.criteria = '';
				this.evidences = [{ evidence: '' }];
				this.criterias = availableCriterias;
				this.criteriaError = '';
				this.evidencesError = [];
			}
			this.$refs.CriteriaWithEvidencesModal.showModal();
		},
		hideModal() {
			this.$refs.CriteriaWithEvidencesModal.hideModal();
		},
		toggleModal() {
			this.$refs.CriteriaWithEvidencesModal.toggleModal();
		},
		handleCloseModal() {
			this.$refs.CriteriaWithEvidencesModal.hideModal();
		},
		handleAddNewClick() {
			this.evidences.push({ evidence: '' });
		},
		handleDeleteEvidence(evidence, index) {
			if (evidence._id) {
				this.deletedEvidences.push(evidence._id);
			}

			this.evidences.splice(index, 1);
		},
		validateForm() {
			if (!this.criteria) this.criteriaError = 'Selecione o critério';

			let hasEvidenceError = false;
			if (this.criteria) {
				this.evidences.forEach((evidence, index) => {
					if (evidence.evidence.trim() === '') {
						hasEvidenceError = true;
						Vue.set(this.evidencesError, index, 'Escreva a evidência');
					}
				});
			}
			if (this.criteriaError === '' && !hasEvidenceError) return true;
			return false;
		},

		async createCriteria() {
			try {
				this.loading = true;
				await this.createCustomReview({
					stage: this.stage,
					role: this.role,
					criteria: this.criteria,
					evidences: this.evidences
				});
				this.$toasts.show({
					message: 'Critério e evidências salvos com sucesso.',
					timeout: 3000,
					type: 'success'
				});
				this.$trackingService.logAdminEvent('Criteria With Evidences Created', {
					stage: this.stage,
					role: this.role,
					criteria: this.criteria
				});
				this.loading = false;
				this.hideModal();
			} catch (err) {
				this.loading = false;
				this.criteriaError = '';
				this.evidencesError = [];
				this.$toasts.show({
					message: 'Erro ao salvar critério e evidências, tente novamente.',
					timeout: 3000,
					type: 'danger'
				});
			}
		},

		async updateCriteria() {
			try {
				this.loading = true;
				const newEvidences = this.evidences.filter(evidence => !evidence._id);
				await this.updateCustomReview({
					customReviewId: this.customReviewId,
					criteriaId: this.criteriaId,
					updateCustomReview: {
						deletedEvidencesId: this.deletedEvidences,
						newEvidences
					}
				});
				this.$toasts.show({
					message: 'Critério e evidências editados com sucesso.',
					timeout: 3000,
					type: 'success'
				});
				this.$trackingService.logAdminEvent('Criteria With Evidences Updated', {
					stage: this.stage,
					role: this.role,
					criteria: this.criteria
				});
				this.loading = false;
				this.hideModal();
			} catch (err) {
				this.loading = false;
				this.hideModal();
				this.criteriaError = '';
				this.evidencesError = [];
				this.$toasts.show({
					message: 'Erro ao editar critério e evidências, tente novamente.',
					timeout: 3000,
					type: 'danger'
				});
			}
		},

		async submitCriterias() {
			if (this.validateForm()) {
				if (this.edit) await this.updateCriteria();
				else await this.createCriteria();
			}
		}
	}
};
</script>
