var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('admin-header',{attrs:{"user":this.user,"tabSelected":"accounts","subtabSelected":"recruiter"}}),_c('div',{staticClass:"padding-horizontal-big padding-horizontal-xl-xxbig"},[_c('div',{staticClass:"page-info margin-horizontal-auto align-items-center display-flex flex-row margin-vertical-xbig justify-content-between"},[_c('span',{staticClass:"font-size-huge ink-base align-center semi-bold margin-left-tiny"},[_vm._v("Gerenciamento de contas Recrutador(a)")]),(_vm.user.permissions.includes('CREATE_ANOTHER_USER'))?_c('b-button',{staticClass:"add-recruiter display-lg-block margin-right-tiny margin-left-normal margin-left-md-tiny font-size-big margin-top-big margin-top-md-0",on:{"clickOrEnter":function($event){return _vm.$refs.settingsAccountModal.showManagerModal()}}},[_c('b-icon',[_c('icon-add')],1),_vm._v("Adicionar conta ")],1):_vm._e()],1),_c('div',{staticClass:"margin-top-big display-flex horizontal-scroll"},[_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"hasActions":"","auto":""},scopedSlots:_vm._u([{key:"fullName",fn:function(props){return [_c('div',{staticClass:"display-flex align-items-center"},[_c('b-avatar',{attrs:{"name":props.item.fullName,"color":props.item.avatarColor,"avatarUrl":props.item.profilePicture,"normal":""}}),_c('span',{staticClass:"margin-left-normal font-size-big"},[_vm._v(_vm._s(props.item.fullName))])],1)]}},{key:"email",fn:function(props){return [_c('div',{staticClass:"display-flex justify-content-between align-items-center"},[_c('span',{staticClass:"font-size-big"},[_vm._v(_vm._s(props.item.email))]),(
								_vm.user.permissions.includes('UPDATE_ANY_USER') &&
									_vm.user.permissions.includes('DELETE_ANY_USER')
							)?_c('b-hovering-menu',{staticStyle:{"cursor":"pointer"},attrs:{"visible":true,"position":"left","options":[
								{
									icon: 'icon-create',
									color: 'ink-lighter-1',
									text: 'Editar',
									method: _vm.showCreateAccountModal,
									params: { props: props }
								},
								{
									icon: 'icon-delete',
									color: 'red-base',
									text: 'Remover',
									method: _vm.showDeleteAccountModal,
									params: { props: props }
								}
							]}}):_vm._e()],1)]}}])})],1)]),(
			_vm.user.permissions.includes('CREATE_ANOTHER_USER') &&
				_vm.user.permissions.includes('UPDATE_ANY_USER')
		)?_c('app-settings-account-modal',{ref:"settingsAccountModal",on:{"onUpdateAccount":_vm.handleUpdateAccount,"onCreateAccount":_vm.handleCreateAccount}}):_vm._e(),(_vm.user.permissions.includes('DELETE_ANY_USER'))?_c('app-delete-account-modal',{ref:"deleteAccountModal",on:{"onRemoveAccount":_vm.handleRemoveAccount}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }