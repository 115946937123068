export const TYPES = {
    SET_ACCRUAL_PAYMENTS: 'SET_ACCRUAL_PAYMENTS',
    SET_PAYMENTS: 'SET_PAYMENTS'
} 

export default {
    [TYPES.SET_ACCRUAL_PAYMENTS](state, { accrualPayments }) {
        state.accrualPayments = accrualPayments;
    },
    [TYPES.SET_PAYMENTS](state, { payments }) {
        state.payments = payments;
    },
}