import axios from 'axios';
import ApiError from './ApiError';

const authApi = axios.create({
	baseURL: process.env.VUE_APP_AUTH_API_URL,
	timeout: 10000
});

authApi.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error.response.data.error.message) {
			error = new ApiError(
				error.response.data.error.message,
				error.response.data.error.code,
				error.response.status
			);
		} else if (error.response.data.error) {
			error = new ApiError(error.response.data.error);
		}

		//console.error(error);

		return Promise.reject(error);
	}
);

const authAdminApi = axios.create({
	baseURL: process.env.VUE_APP_AUTH_API_URL,
	timeout: 10000
});

authAdminApi.interceptors.request.use(
	config => {
		const token = localStorage.getItem('userToken');

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

authAdminApi.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error.response.data.error)
			error = new ApiError(
				error.response.data.error.message,
				error.response.data.error.code,
				error.response.status
			);

		//console.error(error);

		return Promise.reject(error);
	}
);

const bossaBoxApi = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 10000
});

bossaBoxApi.interceptors.request.use(
	config => {
		const token = window.localStorage.getItem('userToken');

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

bossaBoxApi.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error.response.data.error.message)
			error = new ApiError(
				error.response.data.error.message,
				error.response.data.error.code,
				error.response.status
			);

		//console.error(error);

		return Promise.reject(error);
	}
);

export { authApi, authAdminApi, bossaBoxApi };
