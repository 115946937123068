export default {
    'Entrevista Comportamental': [
        'Auto Gestão', 
        'Comunicação Assertiva', 
        'Cultura de Produto', 
        'Mastery (Curiosidade, Autoaprendizagem)', 
        'Protagonismo', 
        'Senso de Time e Colaboração'
    ],
    'Match de Projeto': [
        'CNPJ', 
        'Competências - Back-end', 
        'Competências - Front-End', 
        'Competências - Mobile',  
        'Competências - PD', 
        'Competências - PM', 
        'Disponibilidade para cerimônias', 
        'Oferta de valor', 
        'Regime de contratação', 
        'Senioridade', 
        'Status atual de regime de contratação', 
        'Turno para cerimônias'
    ]
}
