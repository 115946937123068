<template>
	<b-modal ref="disapproveProlancerModal" normal>
		<h3 class="margin-0 semi-bold" slot="header">Reprovar Prolancer</h3>

		<div>
			<p class="font-size-xbig margin-0 white-space-normal">
				Tem certeza que deseja reprovar <strong> {{ name }}</strong
				>? Enviaremos um e-mail com o resultado e com os feedbacks dados.
			</p>
		</div>
		<div
			slot="footer"
			class="display-flex flex-grow justify-content-between justify-content-md-end"
		>
			<b-button
				class="font-size-big"
				link
				color="gray"
				@clickOrEnter="$refs.disapproveProlancerModal.hideModal()"
				>Não</b-button
			>
			<b-button
				danger
				:loading="loading"
				@clickOrEnter="submit()"
				class="margin-left-big font-size-big"
				>Sim</b-button
			>
		</div>
	</b-modal>
</template>

<script>
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';

export default {
	name: 'app-disapprove-prolancer-modal',
	components: {
		BModal,
		BButton
	},
	props: {
		name: {
			type: String
		}
	},
	data() {
		return {
			loading: false
		};
	},
	methods: {
		showModal() {
			this.$refs.disapproveProlancerModal.showModal();
		},
		hideModal() {
			this.$refs.disapproveProlancerModal.hideModal();
		},
		toggleModal() {
			this.$refs.disapproveProlancerModal.toggleModal();
		},
		async submit() {
			this.loading = true;
			this.$emit('updateChallengeInstance', false);
		}
	}
};
</script>
<style lang="scss" scoped>
@media (max-width: 978px) {
	.white-space-normal {
		white-space: normal;
	}
}
</style>
