<template>
	<app-filter
		ref="paymentsFilter"
		:loadingFilterButton="loadingFilterButton"
		@filter="filterPayments"
		@cleanFilters="cleanFilters"
	>
		<p class="font-size-big semi-bold margin-top-big margin-bottom-normal">Agrupar listagem por</p>
		<b-tag
			v-for="item in ['Número da NF', 'Projeto', 'Status', 'Prolancer']"
			:key="item"
			class="margin-right-normal margin-top-normal"
			style="cursor: pointer"
			@click="handleRecommendedClick(item)"
		>
			<span class="ink-base">{{ item }}</span>
		</b-tag>

		<p class="font-size-big semi-bold margin-top-big margin-bottom-0">Período</p>
		<div class="display-flex">
			<b-datepicker
				name="start-date"
				:error="errors.startDate"
				@input="errors.startDate = ''"
				class="margin-top-big margin-right-tiny"
				v-model="startDate"
				placeholder="Data início"
			>
			</b-datepicker>

			<b-datepicker
				name="end-date"
				:error="errors.endDate"
				@input="errors.endDate = ''"
				class="margin-top-big"
				v-model="endDate"
				placeholder="Data final"
			>
			</b-datepicker>
		</div>

		<p class="font-size-big semi-bold margin-top-big margin-bottom-normal">Status</p>
		<bbox-select
			name="status"
			ref="status"
			v-model="status"
			:options="getStatus()"
			inputPlaceholder="'Selecione...'"
		></bbox-select>

		<p class="font-size-big semi-bold margin-top-big margin-bottom-normal">Projeto</p>
		<bbox-select
			name="products"
			ref="products"
			inputPlaceholder="'Selecione...'"
			:options="products"
			v-model="targetProduct"
		></bbox-select>

		<p class="font-size-big semi-bold margin-top-big margin-bottom-normal">Cargo</p>
		<bbox-select
			name="roles"
			ref="roles"
			v-model="targetRole"
			:options="roles"
			inputPlaceholder="'Selecione...'"
		></bbox-select>

		<p class="font-size-big semi-bold margin-top-big margin-bottom-normal">Prolancer</p>
		<bbox-select
			name="roles"
			ref="roles"
			v-model="targetProlancer"
			:options="prolancers"
			inputPlaceholder="'Selecione...'"
		></bbox-select>

		<div class="margin-bottom-normal"></div>
	</app-filter>
</template>

<script>
import AppFilter from '@/components/Filter.vue';
import BboxSelect from '@bossabox-code/design-system/components/Select.vue';
import BDatepicker from '@bossabox-code/design-system/components/Datepicker.vue';
import BTag from '@bossabox-code/design-system/components/Tag';

import financesService from '@/services/finances.service.js';

import { mapGetters, mapActions, mapState } from 'vuex';
import { getRolesNames } from '@/utils/roles.js';

import Vue from 'vue';

Vue.use(financesService);

export default {
	components: {
		AppFilter,
		BboxSelect,
		BDatepicker,
		BTag
	},
	data() {
		return {
			loading: false,
			loadingFilterButton: false,
			page: 1,
			pageCount: 0,
			visibleItemsPerPage: 15,
			errors: {},
			startDate: '',
			endDate: '',
			status: '',
			targetProduct: '',
			targetRole: '',
			targetProlancer: '',
			totalSearchResults: 0,
			targetRoles: [],
			targetSkills: [],
			targetMeetingOptions: [],
			targetTests: [],
			targetChallenges: [],
			targetExperiences: [],
			verifiedCNPJ: false,
			filter: {},
			totalFilterResults: 0,
			queryStringFilter: ''
		};
	},
	props: {
		products: Array,
		roles: Array,
		prolancers: Array
	},
	computed: {
		...mapGetters('auth', ['user']),
		...mapState('payments', ['payments'])
	},
	methods: {
		...mapActions('payments', ['getPayments', 'getFilteredPayments']),
		getRolesNames,
		openFilter() {
			this.$refs.paymentsFilter.openFilter();
		},
		getStatus() {
			return [
				{
					name: 'Aguardando',
					value: 'released'
				},
				{
					name: 'Pagamento realizado',
					value: 'completed'
				},
				{
					name: 'Erro no pagamento',
					value: 'failed'
				}
			];
		},
		async filterPayments() {
			this.loadingFilterButton = true;

			this.filter = {};

			if (this.role) this.queryStringFilter += `&role=${this.role}`;

			if (this.status) this.queryStringFilter += `&paymentStatus=${this.status.value}`;

			if (this.startDate) this.queryStringFilter += `&startDate=${this.startDate}`;

			if (this.endDate) this.queryStringFilter += `&endDate=${this.endDate}`;

			if (this.targetProduct) this.queryStringFilter += `&product=${this.targetProduct}`;

			if (this.targetProlancer)
				this.queryStringFilter += `&prolancer=${this.targetProlancer.value}`;

			this.page = 1;
			const filteredPayments = await this.$financesService.getFilteredPayments(
				this.queryStringFilter
			);
			this.$emit('onFilteredInvoices', filteredPayments);

			this.loadingFilterButton = false;
			this.$refs.paymentsFilter.closeFilter();
		},
		async cleanFilters() {
			this.role = this.status = this.startDate = this.endDate = this.targetProduct = this.targetProlancer =
				'';
			this.filter = {};

			await this.getPayments({ statuses: 'released,completed,failed' });
			this.$emit('onFilteredInvoices', this.payments);
			this.$refs.paymentsFilter.closeFilter();
		},
		getQueryString() {
			return this.queryStringFilter;
		}
	}
};
</script>

<style scoped lang="scss">
@media (max-width: 1200px) {
	.horizontal-scroll {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		height: 100%;
	}
}

.overflow-hidden {
	overflow-x: hidden;
}

.width-empty-div {
	width: 11%;
}

.page-menu {
	align-items: center;
}

@media (max-width: 990px) {
	.page-menu {
		flex-direction: column;
	}
	.page-menu > div:first-child {
		flex-direction: column;
	}
	.page-menu .switcher {
		width: fill-available;
	}

	.approve {
		width: fill-available;
	}

	.approve-div {
		width: 100%;
	}
}

.hidden {
	visibility: hidden;
}
</style>
