<template>
	<b-modal ref="releasePaymentConfirmationModal" normal>
		<h3 class="margin-0 semi-bold" slot="header">Liberar pagamento</h3>

		<div>
			<p class="font-size-xbig margin-0 white-space-normal">
				Deseja confirmar a liberação do pagamento da nota fiscal de
				<b>{{ payment.user && payment.user.fullName }}</b
				>, enviada no dia <b>{{ payment.createdAt | date }}</b
				>?
			</p>
		</div>
		<div
			slot="footer"
			slot-scope=""
			class="display-flex flex-grow justify-content-between justify-content-md-end"
		>
			<b-button class="font-size-big" link color="gray" @clickOrEnter="hideModal()"
				>Cancelar</b-button
			>
			<b-button :loading="loading" @clickOrEnter="submit()" class="margin-left-big font-size-big"
				>Confirmar</b-button
			>
		</div>
	</b-modal>
</template>

<script>
import BModal from '@bossabox-code/design-system/components/Modal.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import Vue from 'vue';

Vue.filter('date', function(value) {
	if (!value) return '';
	let date = new Date(value);
	const day = date.toLocaleString('default', { day: 'numeric' });
	const month = date.toLocaleString('default', { month: 'long' });
	const year = date.toLocaleString('default', { year: 'numeric' });
	return day + ' de ' + month + ', ' + year;
});

export default {
	name: 'app-release-payment-confirmation-modal',
	components: {
		BModal,
		BButton
	},
	props: {
		payment: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: false
		};
	},
	async mounted() {},
	methods: {
		showModal() {
			this.loading = false;
			this.$refs.releasePaymentConfirmationModal.showModal();
		},
		hideModal() {
			this.$refs.releasePaymentConfirmationModal.hideModal();
		},
		toggleModal() {
			this.$refs.releasePaymentConfirmationModal.toggleModal();
		},
		async submit() {
			this.loading = true;

			this.$emit('releasePayment');

			this.loading = false;
			this.hideModal();
		}
	}
};
</script>
