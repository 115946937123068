import { customReviewService } from '../../services/customReview.service';
import { TYPES } from './mutations';

export default {
	async createCustomReview({ commit }, customReview) {
		const response = await customReviewService.createCustomReview(customReview);
		commit(TYPES.SET_CUSTOM_REVIEW, response.createdPersonalization);
		return response;
	},

	async getCustomReview({ commit }, { stage, role }) {
		const response = await customReviewService.getCustomReview(stage, role);
		commit(TYPES.SET_CUSTOM_REVIEW, response);
		return response;
	},

	async setCustomReview({ commit }, newValue) {
		commit(TYPES.SET_CUSTOM_REVIEW, newValue);
	},

	async updateCustomReview({ commit }, { customReviewId, criteriaId, updateCustomReview }) {
		const response = await customReviewService.updateCustomReview(customReviewId, criteriaId, updateCustomReview);
		commit(TYPES.SET_CUSTOM_REVIEW, response.changeCustomReview);
		return response;
	},

	async deleteCustomReview({ commit }, { customReviewId, criteriaId }) {
		await customReviewService.deleteCustomReview(customReviewId, criteriaId);
		commit(TYPES.DELETE_CUSTOM_REVIEW, criteriaId);
	}
};
