<template>
	<div>
		<admin-header :user="this.user" tabSelected="accounts" subtabSelected="internal" />

		<div class="padding-horizontal-big padding-horizontal-xl-xxbig">
			<div
				class="page-info margin-horizontal-auto display-flex flex-row margin-vertical-xbig justify-content-between"
			>
				<span
					class="padding-left-lg-0 align-center margin-left-tiny align-left-sm font-size-huge ink-base semi-bold"
					>Gerenciamento de contas internas</span
				>
				<b-button
					v-if="user.permissions.includes('CREATE_ANOTHER_ADMIN')"
					class="add-account display-lg-block margin-left-normal margin-left-md-auto margin-right-tiny font-size-big margin-top-big margin-top-md-0"
					@clickOrEnter="$refs.settingsAccountModal.showInternalModal()"
				>
					<b-icon> <icon-add /> </b-icon>Adicionar conta
				</b-button>
			</div>
			<div class="margin-top-big display-flex horizontal-scroll">
				<b-table :items="items" :fields="fields" auto>
					<template slot="fullName" slot-scope="props">
						<div class="display-flex align-items-center">
							<b-avatar
								:name="props.item.fullName"
								:color="props.item.avatarColor"
								:avatarUrl="props.item.profilePicture"
								normal
							/>
							<span class="margin-left-normal font-size-big">{{ props.item.fullName }}</span>
						</div>
					</template>
					<template slot="email" slot-scope="props">
						<div class="display-flex justify-content-between align-items-center">
							<span class="font-size-big">{{ props.item.email }}</span>
							<b-hovering-menu
								v-if="
									user.permissions.includes('UPDATE_ANY_ADMIN') &&
										user.permissions.includes('DELETE_ANY_ADMIN')
								"
								:visible="true"
								position="left"
								style="cursor: pointer"
								:options="[
									{
										icon: 'icon-create',
										color: 'ink-lighter-1',
										text: 'Editar',
										method: showCreateAccountModal,
										params: { props }
									},
									{
										icon: 'icon-delete',
										color: 'red-base',
										text: 'Remover',
										method: showDeleteAccountModal,
										params: { props }
									}
									// eslint-disable-next-line no-mixed-spaces-and-tabs
								]"
							/>
						</div>
					</template>
				</b-table>
			</div>
		</div>
		<app-settings-account-modal
			v-if="
				user.permissions.includes('CREATE_ANOTHER_ADMIN') ||
					user.permissions.includes('UPDATE_ANY_ADMIN')
			"
			ref="settingsAccountModal"
			@onUpdateAccount="handleUpdateAccount"
			@onCreateAccount="handleCreateAccount"
		/>
		<app-delete-account-modal
			v-if="user.permissions.includes('DELETE_ANY_ADMIN')"
			ref="deleteAccountModal"
			@onRemoveAccount="handleRemoveAccount"
		/>
	</div>
</template>

<script>
import BTable from '@bossabox-code/design-system/components/Table.vue';
import BButton from '@bossabox-code/design-system/components/Button.vue';
import AppSettingsAccountModal from './SettingsAccountModal.vue';
import BAvatar from '@bossabox-code/design-system/components/Avatar.vue';
import BHoveringMenu from '@bossabox-code/design-system/components/HoveringMenu.vue';
import BIcon from '@bossabox-code/design-system/components/Icon.vue';
import AppDeleteAccountModal from './DeleteAccountModal.vue';
import { mapGetters, mapActions } from 'vuex';
import { getRolesNames } from '../utils/roles.js';
import AdminHeader from './components/AdminHeader.vue';

export default {
	components: {
		BTable,
		BIcon,
		BButton,
		BAvatar,
		AppSettingsAccountModal,
		AppDeleteAccountModal,
		BHoveringMenu,
		AdminHeader
	},
	data() {
		return {
			fields: [
				{
					key: 'fullName',
					value: 'Nome'
				},
				{
					key: 'roles',
					value: 'Tipo de conta'
				},
				{
					key: 'email',
					value: 'E-mail'
				}
			],
			items: []
		};
	},
	computed: {
		...mapGetters('auth', ['user'])
	},
	methods: {
		...mapActions('accounts', ['getInternalAccounts']),
		showDeleteAccountModal(accountObject) {
			this.$refs.deleteAccountModal.showModal(accountObject.props.item);
		},
		showCreateAccountModal(accountObject) {
			this.$refs.settingsAccountModal.showInternalModal(accountObject.props.item);
		},
		handleRemoveAccount(accountObject) {
			this.items.splice(this.items.indexOf(accountObject), 1);
		},
		handleUpdateAccount(accountObject) {
			const index = this.items.findIndex(x => x._id == accountObject._id);
			if (index != -1) {
				accountObject.roles = getRolesNames(accountObject.roles);
				this.items.splice(index, 1, accountObject);
			}
		},
		handleCreateAccount() {
			this.getInternalUsers();
		},
		async getInternalUsers() {
			this.items = [];
			const internalUsers = await this.getInternalAccounts();
			for (let i = 0; i < internalUsers.length; i++) {
				let user = internalUsers[i];
				let userTable = {
					...user,
					roles: getRolesNames(user.roles),
					profilePicture: !user.profilePicture ? '' : user.profilePicture
				};
				this.items.push(userTable);
			}
		}
	},
	beforeMount() {
		this.getInternalUsers();
	}
};
</script>

<style scoped lang="scss">
.horizontal-scroll {
	@media (max-width: 1200px) {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		height: 100%;
	}
}

.page-info {
	align-items: center;
}

@media (max-width: 768px) {
	.page-info {
		flex-direction: column;
	}
	.page-info > div:first-child {
		flex-direction: column;
	}
	.page-info .add-account {
		width: -webkit-fill-available;
	}
}
</style>
