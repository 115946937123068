import Vue from 'vue';
import Vuex from 'vuex';
import auth from './store/auth.store.js';
import accounts from './store/accounts.store';
import challenges from './store/challenges.store';
import payments from './store/payments';
import customReview from './store/customReview';

Vue.use(Vuex);

export default new Vuex.Store({
	// As stores estão sendo concatenadas como uma unica store global.
	// Modularizar a store possibilita que nossa estrutura seja melhor escalável.
	// https://vuex.vuejs.org/guide/modules.html
	modules: {
		auth,
		accounts,
		challenges,
		payments,
		customReview,
	}
});
